import React from "react";
import {
  Typography,
  Button,
  TextField,
  Container,
  Grid,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import whyJoin1 from "../../images/whyJoin1.png";
import whyJoin2 from "../../images/whyJoin2.png";
import whyJoin3 from "../../images/whyJoin3.png";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

const MuiGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

const WhyJoinBox = styled(Box)(({ theme }) => ({
  padding: "5rem 4rem",
  [theme.breakpoints.down("md")]: {
    padding: "4rem 2rem",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "2rem 0.8rem",
  },
  background: "#fff",
  "& .MuiGrid-item.leftGrid": {
    "& .left_stack": {
      width: "fit-content",
      "& ul": {
        "& li": {
          display: "flex",
          alignItems: "center",
          gap: "10px",
          "& > svg": {
            color: theme.palette.primary.main,
          },
          "& >.MuiTypography-root": {
            flex: 1,
            fontWeight: "bold",
            color: "rgba(0, 0, 0, 0.8)",
          },
        },
      },
    },
  },

  "& .MuiGrid-item.rightGrid": {
    "& .MuiBox-root:not(.btn)": {
      position: "relative",
      borderRadius: "3px",
      overflow: "hidden",
      "& > img": {
        position: "absolute",
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  },
}));

const WhyJoin = () => {
  return (
    <WhyJoinBox id="about">
      <Container
        sx={{
          // border: "1px solid black",
          py: 2,
        }}
      >
        <MuiGrid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            sx={{
              display: "flex",
              justifyContent: { sm: "center", md: "flex-start" },
            }}
            className="leftGrid"
          >
            <Stack spacing={{ xs: 1.5, sm: 2.5, md: 3 }} className="left_stack">
              <Typography color="primary" variant="h6">
                Whats Cred Management?
              </Typography>
              <Typography variant="h3" color="black" maxWidth={450}>
                Why Join to Cred Mangement Network?
              </Typography>
              <Typography variant="body1" maxWidth={550}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                elementum, dignissim semper turpis cras lorem in. In eu at
                vestibulum erat.
              </Typography>
              <Stack component="ul" spacing={2}>
                <Box component="li">
                  <RadioButtonCheckedIcon />
                  <Typography variant="body1">Groups</Typography>
                </Box>
                <Box component="li">
                  <RadioButtonCheckedIcon />
                  <Typography variant="body1">Team Managment</Typography>
                </Box>
                <Box component="li">
                  <RadioButtonCheckedIcon />
                  <Typography variant="body1">Project Managment</Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            gap={{ xs: 2, sm: 3 }}
            className="rightGrid"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: { xs: "0.8rem", sm: "1rem" },
              justifyContent: { sm: "center", md: "flex-end" },
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: { xs: "300px", sm: "264px" },
                height: { xs: "330px", sm: "370px" },
              }}
            >
              <img src={whyJoin1} style={{ zIndex: 1 }} alt="why join" />
              <Box
                className="btn"
                sx={{
                  position: "absolute",
                  top: "42%",
                  left: "38%",
                  right: "0%",
                  zIndex: 3,
                }}
              >
                <IconButton color="secondary" size="large">
                  <PlayCircleIcon sx={{ fontSize: "2.75rem" }} />
                </IconButton>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                gap: { xs: "0.8rem", sm: "1rem" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "300px", sm: "260px" },
                  height: { xs: "300px", sm: "260px" },
                }}
              >
                <img src={whyJoin3} />
              </Box>
              <Box
                sx={{
                  width: { xs: "300px", sm: "260px" },
                  height: { xs: "300px", sm: "260px" },
                }}
              >
                <img src={whyJoin2} />
              </Box>
            </Box>
          </Grid>
        </MuiGrid>
      </Container>
    </WhyJoinBox>
  );
};

export default WhyJoin;
