import { Typography } from '@mui/material'
import React, { useEffect } from 'react'

function Feature() {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'auto' })
	}, [])
	return (
		<div>
			<Typography variant='h1'>Features</Typography>
			<Typography variant='body1'>
				Lorem ipsum dolor sit, amet consectetur adipisicing elit.
				Maiores, ipsum nihil hic error, officia quae adipisci iste,
				optio cumque quos mollitia excepturi? Ad porro quia perspiciatis
				aliquid debitis, adipisci laborum laudantium consequuntur, neque
				dolore, aperiam sunt labore recusandae architecto dicta autem
				inventore? Incidunt, iure quod.
			</Typography>
		</div>
	)
}

export default Feature
