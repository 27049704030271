import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Typography, Button, TextField, Container, Grid, Box, CircularProgress, Stack, Link as MuiLink, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../firebase/config'

const MuiGrid = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0px auto",
  height: "100%",
}))

const ForgetBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "1.5rem",
  [theme.breakpoints.down("sm")]: {
    gap: "1rem",
  },
  "& > .MuiTypography-root.title": {
    textAlign: "center",
    color: "#090914",
  },
  "& > .MuiTypography-root.txt": {
    textAlign: "center",
    maxWidth: "490px",
  },
  "& > form": {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1.2rem",
    background: "#FFFFFF",
    boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.06), 0px 10px 15px rgba(0, 0, 0, 0.03)",
    borderRadius: "4px",
    padding: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem 1rem",
    }
  }
}))

const MainForm = styled("form")(({ theme }) => ({
  "& > .error": {
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "20px",
    fontSize: "16px",
    color: theme.palette.error.main,
    background: "pink",
    border: "1px solid red",
    borderRadius: "4px",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      padding: "3px",
      fontSize: "12px",
    }
  },

  "& .MuiFormControl-root": {

    "& .MuiFormHelperText-root": {
      fontSize: "14px",
      fontWeight: "bold",
      marginTop: "8px",
      marginLeft: "4px",
    }
  },
  "& .action": {
    textAlign: "center",
    "& > button": {
      padding: theme.spacing(2),
      fontWeight: "700",
      textTransform: "initial",
      fontSize: theme.typography.body1.fontSize,
      display: "inline-flex",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
        fontSize: "14px",
      },
      "& > span.MuiCircularProgress-root": {
        width: "20px !important",
        height: "20px !important",
        color: "inherit",
        marginRight: "10px",
        marginTop: "2px",
      },
    }
  }

}))

const Reset = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const isSm = useMediaQuery('(max-width:600px)');
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [authError, setAuthError] = useState(null)

  console.log(window.location.origin + "/login", "url")

  const onSubmit = async ({ email }) => {
    setLoading(true)
    try {
      var actionCodeSettings = {
        // After password reset, the user will be give the ability to go back
        // to this page.
        url: window.location.origin + "/login",
        handleCodeInApp: false
      };
      await sendPasswordResetEmail(auth, email, actionCodeSettings)
      reset()
      setLoading(false)
      return navigate('/login')
    } catch (error) {
      setAuthError(error)
      setTimeout(() => {
        setAuthError(null)
      }, 4000)
    }
  }

  return (
    <Container
      sx={{
        marginTop: "4%",
        paddingY: 2,
      }}
    >
      <MuiGrid container>
        <Grid item xs={12} sm={8} md={6} maxWidth={530}>
          <ForgetBox>
            <Typography className='title' variant='h2'>Reset Password</Typography>
            <Typography className='txt' variant='body1' width="85%">
              That's okay, it happens! Write your login email below and
              we will send you a link to reset your password.
            </Typography>
            <MainForm onSubmit={handleSubmit(onSubmit)}>
              {authError && <Typography className="error">{authError?.message}</Typography>}
              <Stack direction="column" spacing={2}>
                <TextField
                  type='email'
                  variant="outlined"
                  inputProps={{ sx: { px: isSm ? 1 : 2, py: isSm ? 1 : 2 }, }}
                  fullWidth
                  placeholder='Email'
                  autoComplete="off"
                  error={!!errors.email}
                  helperText={errors.email && "This is required"}
                  {...register('email', { required: true })}
                />
              </Stack>
              <Stack className="action" direction="column" spacing={2}>
                <Button
                  type='submit'
                  variant='contained'
                  fullWidth
                  disabled={loading || !!authError}
                >
                  {loading && <CircularProgress />}
                  Reset
                </Button>
              </Stack>
              <Stack>
                <Typography textAlign="center" width="100%" variant='body1'>
                  Don't have an account?{" "}
                  <MuiLink
                    sx={{ textDecoration: "none", fontWeight: "700", cursor: "pointer" }}
                    onClick={() => navigate("/register")}
                  >
                    Resgiter
                  </MuiLink>
                </Typography>
                <Typography textAlign="center" width="100%" variant='body1' fontWeight="700">
                  OR
                </Typography>
                <Typography textAlign="center" width="100%" variant='body1'>
                  Alraedy have an account?{" "}
                  <MuiLink
                    sx={{ textDecoration: "none", fontWeight: "700", cursor: "pointer" }}
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </MuiLink>
                </Typography>
              </Stack>
            </MainForm>
          </ForgetBox>
        </Grid>
      </MuiGrid>
    </Container>
  )
}

export default Reset
