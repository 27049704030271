import { createContext, useReducer, useEffect } from 'react'
import { db } from '../firebase/config'
import { collection, collectionGroup, doc, getDoc, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { useAuthContext } from '../hooks/useAuthContext'

export const TeamOwnersContext = createContext()


export const teamOwnersReducer = (state, action) => {
    switch (action.type) {
        case 'setIsTeamMember':
            return { ...state, isTeamMember: action.payload }
        case 'setTeamOwners':
            return { ...state, teamOwners: action.payload }
        case 'removeTeamsData':
            return { ...state, teamOwners: null, isTeamMember: false }
        case 'setIsFetched':
            return { ...state, isFetched: true }
        case 'removeIsFetched':
            return { ...state, isFetched: false }
        default:
            return state
    }
}

export const TeamOwnersContextProvider = ({ children }) => {
    const { user } = useAuthContext()

    const [state, dispatch] = useReducer(teamOwnersReducer, {
        teamOwners: [],
        isTeamMember: false,
        isFetched: false
    })

    useEffect(() => {
        const getTeamsImIn = async () => {
            const teamColRef = query(collectionGroup(db, "team"), where("email", "==", user.email));
            onSnapshot(teamColRef, async teamSnap => {
                let results = []
                teamSnap.docs.forEach(obj => {
                    results.push({ ...obj.data(), ownerId: obj.ref.parent.parent.id })
                })
                dispatch({ type: 'setIsTeamMember', payload: !teamSnap.empty })

                const getTeamOwners = await Promise.all(results.map(async c => {
                    let ownerTeam = await getDocs(collection(db, "users", c.ownerId, 'team'))
                    ownerTeam = ownerTeam.docs.map(doc => ({ ...doc.data(), id: doc.id }))
                    const owner = await getDoc(doc(db, "users", c.ownerId))
                    return ({ ...owner.data(), uid: owner.id, team: ownerTeam })
                }))
                let getTeamOwnersData = results.map(me => {
                    return ({
                        ...me,
                        owner: getTeamOwners.find(owner => owner.uid === me.ownerId)
                    })
                })
                dispatch({ type: 'setTeamOwners', payload: getTeamOwnersData })
                dispatch({ type: 'setIsFetched' })
            })
        }
        user && !state.isFetched && getTeamsImIn()
    }, [user, state.isFetched])

    return (
        <TeamOwnersContext.Provider value={{ ...state, dispatch }}>
            {children}
        </TeamOwnersContext.Provider>
    )
}
