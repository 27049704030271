import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";

function EmailVerification() {
  return (
    <Container
      className="custom-email-verify"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ padding: "15px", boxShadow: "0px 0px 5px #ccc" }}>
          <Typography
            variant="h6"
            sx={{ marginBottom: "30px", color: "#4a3affba" }}
          >
            Your email has been verified
          </Typography>
          <Typography variant="body1">
            You can now sign in with your new account
          </Typography>
        </Box>
      </Grid>
    </Container>
  );
}

export default EmailVerification;
