import { Typography } from '@mui/material'
import React, { useEffect } from 'react'

function About() {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}, [])
	return (
		<div>
			<Typography variant='h1'>About</Typography>
			<Typography variant='body1'>
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
				repellendus minus obcaecati at saepe sunt, suscipit consectetur
				id officiis ipsam corporis eligendi doloremque? Alias enim saepe
				quo, facilis provident quos similique accusantium ullam tempora
				maxime cum non reprehenderit, sunt quaerat voluptatibus id
				eligendi dolorem minus.
			</Typography>
		</div>
	)
}

export default About
