import Profile from './components/Profile'
import UserInfo from './components/UserInfo'
import { styled } from '@mui/material/styles'
import { Container, Grid } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext'
import TeamInfoModal from './components/TeamInfoModal'
import { getCustomClaimRole } from '../../firebase'
import CircularLoader from '../../components/CircularLoader/CircularLoader'


const MuiGrid = styled(Grid, {
  shouldForwardProp: prop => prop !== "cardWrapped" && prop !== "userRole"
})(({ cardWrapped, userRole }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: userRole === "team" ? "flex-start" : "center",

  "& .gridItem": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    minHeight: cardWrapped ? 'auto' : "420px",
    "& > :first-of-type": {
      flex: 1,
    },
    "& > :last-of-type": {
      flex: 1.5,
    }
  },


}))



const Settings = () => {
  const [cardWrapped, setCardWrapped] = useState(null);
  const ref = useRef()
  const { user: currentUser } = useAuthContext()
  const [thumbnailUrl, setThumbnailUrl] = useState(null)
  const [displayName, setDisplayName] = useState("")
  const [userName, setUserName] = useState("")
  const [openTeam, setOpenTeam] = useState(false)
  const [userRole, setUserRole] = useState(null)
  const [isLoading, setIsLoading] = useState(true)


  useEffect(() => {
    (async _ => {
      setIsLoading(true)
      if (currentUser) {
        setThumbnailUrl(currentUser?.photoURL || "")
        setDisplayName(currentUser?.displayName || "")
        setUserName(currentUser?.displayName || "")
        let role = await getCustomClaimRole()
        setUserRole(role)
      }
      setIsLoading(false)
    })()
  }, [currentUser])


  const updateDimensions = () => {
    setCardWrapped(ref?.current?.getBoundingClientRect().top > 420 ? true : false)
  }

  useEffect(() => {
    if (ref) {
      cardWrapped === null && updateDimensions();
      window.addEventListener("resize", updateDimensions)
    };
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleTeamOpen = () => setOpenTeam(true);
  const handleTeamClose = () => setOpenTeam(false);


  if (isLoading) {
    return <CircularLoader />
  }

  return (
    <Container
      sx={{
        // border: "1px solid red",
        alignSelf: "normal",
        display: "flex",
      }}
    >
      <MuiGrid container cardWrapped={cardWrapped} spacing={2} userRole={userRole}
        sx={{
          // mt: { xs: 0, sm: cardWrapped ? 0 : 2, md: cardWrapped ? 0 : 5, lg: 0 }
        }}>
        {userRole && userRole === "team" &&
          <Grid item xs={12}>
            <TeamInfoModal
              open={openTeam}
              handleOpen={handleTeamOpen}
              handleClose={() => {
                handleTeamClose()
              }}
              user={currentUser}
              cardWrapped={cardWrapped}
            />
          </Grid>
        }
        <Grid className="gridItem" item xs={12} md={10} >
          <UserInfo
            userRole={userRole}
            cardWrapped={cardWrapped}
            thumbnailUrl={thumbnailUrl}
            setThumbnailUrl={setThumbnailUrl}
            user={currentUser}
            displayName={displayName}
            setDisplayName={setDisplayName}
            userName={userName}
          />
          <Profile
            cardWrapped={cardWrapped}
            refrence={ref}
            thumbnailUrl={thumbnailUrl}
            setThumbnailUrl={setThumbnailUrl}
            user={currentUser}
            displayName={displayName}
            setDisplayName={setDisplayName}
          />
        </Grid>
      </MuiGrid>
    </Container >
  )
}

export default Settings
