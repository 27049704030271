import React from "react";
import { useSearchParams } from "react-router-dom";
import ResetPassword from "./components/resetPassword";
import EmailVerification from "./components/emailVerification";

const CustomAuth = () => {
  let [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");
  const continueUrl = searchParams.get("continueUrl");
  const lang = searchParams.get("lang") || "en";

  return (
    <>
      {mode === "verifyEmail" ? (
        <EmailVerification />
      ) : mode === "resetPassword" ? (
        <ResetPassword actionCode={actionCode} />
      ) : null}
    </>
  );
};

export default CustomAuth;
