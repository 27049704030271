import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  styled
} from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { tooltipClasses } from "@mui/material/Tooltip";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
// import Container from '@mui/material/Container';
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import {
  Person,
  AttachMoney,
  PersonAddAlt1,
  NearMe,
} from "@mui/icons-material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    //   maxWidth: 500,
    backgroundColor: "#4A3AFF",
    fontSize: "15px",
    border: "1px solid #4A3AFF",
  },
  [`& .${tooltipClasses.arrow}`]: {
    //   maxWidth: 500,
    color: "#4A3AFF",
  },
});

const Affiliatedashboard = () => {
  const { user } = useAuthContext();
  const [userAffiliation, SetUserAffiliation] = useState(null);
  const [textCopied, setTextCopied] = useState(false);

  const FirstPromoterApiKey = process.env.REACT_APP_FIRSTPROMOTER_API_KEY;
  const createPromoterUrl = "https://firstpromoter.com/api/v1/promoters/create";
  const getPromoterUrl = "https://firstpromoter.com/api/v1/promoters/show";
  const requestBody = new URLSearchParams({
    email: user?.email,
    first_name: user?.displayName?.split(" ")[0],
    last_name: user?.displayName?.split(" ")?.slice(1)?.join(" "),
    cust_id: user?.uid,
  });
  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(getPromoterUrl, {
          headers: {
            "x-api-key": FirstPromoterApiKey,
          },
          params: {
            cust_id: user?.uid,
            email: user?.email,
          },
        });
        console.log("Promoter: ", response.data);
        SetUserAffiliation(response.data);
      } catch (error) {
        console.log(
          "Error in fetching promoter:",
          error?.response?.data?.error
        );
      }
    })();
  }, []);

  const createPromoter = async () => {
    try {
      const response = await axios.post(createPromoterUrl, requestBody, {
        headers: {
          "x-api-key": FirstPromoterApiKey,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      console.log("Promoter created successfully!", response?.data);
      SetUserAffiliation(response.data);
    } catch (error) {
      console.error("Error creating promoter:", error);
    }
  };

  return (
    <Container className="affiliate-dashboard-component">
      {userAffiliation ? (
        <Box>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid xs={12} sm={6} md={7} sx={{ padding: "10px" }}>
              <Typography variant="h3" color="black" maxWidth={450}>
                Welcome {userAffiliation?.profile?.first_name}{" "}
                {userAffiliation?.profile?.last_name} to Sendmea Promotions
              </Typography>
              <Typography
                variant="body1"
                color="black"
                sx={{ marginTop: "15px", marginBottom: "15px" }}
              >
                you are earning 30% recuring commission on all direct referals
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              md={5}
              sx={{ padding: "10px", display: "flex" }}
              justifyContent={{ xs: "start", sm: "end" }}
            >
              <Button
                sx={{ textTransform: "capitalize" }}
                variant="contained"
                color="primary"
                component={Link}
                to="/"
                //   className={location.pathname === "/register" ? "active" : ""}
              >
                Advance Detail Dashboard
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12} sm={6} md={7} sx={{ padding: "10px" }}>
              <Typography
                variant="body1"
                color="black"
                sx={{ marginTop: "5px", display: "flex" }}
              >
                <Typography
                  sx={{
                    marginRight: "5px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Referal Link :{" "}
                </Typography>
                <CustomTooltip title={textCopied ? "Copied" : "Copy value"}>
                  <Button
                    sx={{ textTransform: "none", textDecoration: "underline" }}
                    variant="contained"
                    color="primary"
                    target="_blank"
                    onClick={(e) => {
                      navigator.clipboard.writeText(e.target.innerText);
                      setTextCopied(true);
                      setTimeout(() => {
                        setTextCopied(false);
                      }, 5000);
                    }}
                    // endIcon={<DeleteIcon />}
                    //   className={location.pathname === "/register" ? "active" : ""}
                  >
                    {userAffiliation?.promotions?.[0]?.referral_link}
                  </Button>
                </CustomTooltip>
                {/* <IconButton>
                <DriveFileRenameOutlineOutlinedIcon fontSize="large" />
              </IconButton> */}
              </Typography>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              md={5}
              sx={{ padding: "10px" }}
              display={{ sm: "flex" }}
              justifyContent={{ xs: "start", sm: "end" }}
            >
              <Typography
                variant="body1"
                color="black"
                sx={{ marginTop: "5px", display: "flex" }}
              >
                <Typography
                  sx={{
                    marginRight: "5px",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                  }}
                >
                  Referal ID :{" "}
                </Typography>
                <CustomTooltip title={textCopied ? "Copied" : "Copy value"}>
                <Button
                  sx={{ textTransform: "none", textDecoration: "underline" }}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    navigator.clipboard.writeText(e.target.innerText);
                    setTextCopied(true);
                    setTimeout(() => {
                      setTextCopied(false);
                    }, 5000);
                  }}
                  // endIcon={<DeleteIcon />}
                  //   className={location.pathname === "/register" ? "active" : ""}
                >
                  {userAffiliation?.default_ref_id}
                </Button>
                </CustomTooltip>
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid xs={12} md={3} sm={6}>
              <Box
                sx={{
                  backgroundColor: "#f2f9fe",
                  margin: "5px",
                  padding: "10px",
                  minHeight: "120px",
                }}
              >
                <Grid container>
                  <Grid xs={12} md={6}>
                    <Typography
                      variant="h6"
                      textAlign={{ xs: "center", md: "left" }}
                    >
                      Clicks Count
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign={{ xs: "center", md: "left" }}
                    >
                      {userAffiliation?.promotions?.[0]?.visitors_count}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6}
                    sx={{ display: "flex", alignItems: "center" }}
                    justifyContent={{ xs: "center" }}
                  >
                    <NearMe fontSize="large" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid xs={12} md={3} sm={6}>
              <Box
                sx={{
                  backgroundColor: "#f2f9fe",
                  margin: "5px",
                  padding: "10px",
                  minHeight: "120px",
                }}
              >
                <Grid container>
                  <Grid xs={12} md={6}>
                    <Typography
                      variant="h6"
                      textAlign={{ xs: "center", md: "left" }}
                    >
                      Total Referrals
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign={{ xs: "center", md: "left" }}
                    >
                      {userAffiliation?.promotions?.[0]?.leads_count}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6}
                    sx={{ display: "flex", alignItems: "center" }}
                    justifyContent={{ xs: "center" }}
                  >
                    <PersonAddAlt1 fontSize="large" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid xs={12} md={3} sm={6}>
              <Box
                sx={{
                  backgroundColor: "#f2f9fe",
                  margin: "5px",
                  padding: "10px",
                  minHeight: "120px",
                }}
              >
                <Grid container>
                  <Grid xs={12} md={6}>
                    <Typography
                      variant="h6"
                      textAlign={{ xs: "center", md: "left" }}
                    >
                      Customers
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign={{ xs: "center", md: "left" }}
                    >
                      {userAffiliation?.promotions?.[0]?.active_customers_count}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6}
                    sx={{ display: "flex", alignItems: "center" }}
                    justifyContent={{ xs: "center" }}
                  >
                    <Person fontSize="large" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid xs={12} md={3} sm={6}>
              <Box
                sx={{
                  backgroundColor: "#f2f9fe",
                  margin: "5px",
                  padding: "10px",
                  minHeight: "120px",
                }}
              >
                <Grid container>
                  <Grid xs={12} md={6}>
                    <Typography
                      variant="h6"
                      textAlign={{ xs: "center", md: "left" }}
                    >
                      Total Income
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign={{ xs: "center", md: "left" }}
                    >
                      $
                      {userAffiliation?.earnings_balance
                        ? userAffiliation?.earnings_balance
                        : `0`}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={12}
                    md={6}
                    sx={{ display: "flex", alignItems: "center" }}
                    justifyContent={{ xs: "center" }}
                  >
                    <AttachMoney fontSize="large" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            textAlign: "center",
            height: "60vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Typography variant="h4">For Affiliation</Typography>
          <Button variant="outlined" onClick={createPromoter}>
            Get Enrolled
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default Affiliatedashboard;
