import React, { useEffect, useState } from 'react'
import { useParams, Navigate, useNavigate } from 'react-router-dom'
import { db } from '../../../firebase/config'
import { collection, doc, getDoc, getDocs, query, updateDoc, where, } from "firebase/firestore";
import { styled } from '@mui/material/styles'
import { Grid, Container, Typography, Button, CircularProgress, Avatar, Stack, Tooltip } from '@mui/material'
import { Box } from '@mui/system';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { RadioButtonChecked } from '@mui/icons-material';
import WarningIcon from '@mui/icons-material/Warning';
import ForwardIcon from '@mui/icons-material/Forward';
import CircularLoader from '../../../components/CircularLoader/CircularLoader';
import { useSnackbar } from 'notistack';

const MuiGrid = styled(Grid)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
}))

const StyledInvitationBox = styled(Box)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    boxShadow: "rgb(0 0 0 / 5%) 0px 0px 5px 1px",
    position: "relative",
    padding: theme.spacing(3),
    margin: "auto",
    textAlign: "center",
    maxWidth: "500px",
    minHeight: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1rem",
    "& ul": {
        alignSelf: "flex-start",
        "& li": {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "8px",
            "& > svg": {
                color: theme.palette.primary.main,
                fontSize: "1rem",
            },
            "& >.MuiTypography-root": {
                color: "rgba(0, 0, 0, 0.8)",
                textAlign: "left",
            }

        }
    },

    "& > .fake": {
        flex: 1,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
    }

}))

const AcceptInvitation = () => {

    const params = useParams()

    const navigate = useNavigate()
    const { user: currentUser } = useAuthContext()
    const { inviterId, inviteeEmail } = params
    const { enqueueSnackbar } = useSnackbar()

    const [inviterUser, setInviterUser] = useState(null)
    const [loading, setLoading] = useState(null)
    const [inviteeAccepted, setInviteeAccepted] = useState(false)
    const [checkInvitation, setCheckInvitation] = useState(true)
    const [message, setMessage] = useState("")
    const [pageLoading, setPageLoading] = useState(true)


    const addInvitee = async () => {
        try {
            setLoading(true)
            const inviteeTeamDocRef = doc(db, "users", inviterId, "team", inviteeEmail)
            const getInviteeTeamDoc = await getDoc(inviteeTeamDocRef)
            if (getInviteeTeamDoc.exists) {
                await updateDoc(inviteeTeamDocRef, { ...getInviteeTeamDoc.data(), status: "accepted" });
                setInviteeAccepted(true)
                setMessage("Congratulations! You're added to a new team")
            }
            else {
                setInviteeAccepted(false)
                enqueueSnackbar("Invite might be expire or deleted by team owner", { variant: "error" })
            }
            setLoading(false);
        } catch (error) {
            console.log(error)
            setLoading(false);
        }
    }


    const getUserTeamsData = async (memberQueryUsers, teamMembersArray) => {
        let getTeamUsers = await Promise.all(
            memberQueryUsers.map(async queryUserSnap => {
                let getUser = await queryUserSnap
                if (queryUserSnap.empty) return false;
                getUser = getUser.docs.map(doc => ({ ...doc.data(), uid: doc.id }))[0]
                return getUser
            })
        )
        let getTeamUsersCombineData = teamMembersArray.map(member => {
            return ({
                ...member,
                ...(getTeamUsers.find(owner => owner?.email === member.email) || {})
            })
        })

        return getTeamUsersCombineData
    }

    const checkVerifaication = async () => {
        setPageLoading(true)
        const userTeamSnap = await getDocs(collection(db, "users", inviterId, "team"))
        try {
            let checkInviteeExistInTeam = userTeamSnap.docs.map(doc => ({ ...doc.data() })).find(doc => doc.email === inviteeEmail)
            if (checkInviteeExistInTeam) {
                // check invitee (who's been invited) exists in the inviter(who send invite) team and accepted it's invitation(status==="accpeted"))
                setCheckInvitation(true)
                const teamMembersArr = []
                const memberQueryUsers = []
                userTeamSnap.docs.forEach(async member => {
                    member.data()?.email !== currentUser.email && teamMembersArr.push({ ...member.data() })
                    const queryOnMember = query(collection(db, "users"), where("email", "==", member.data().email))
                    memberQueryUsers.push(getDocs(queryOnMember))
                });
                if (checkInviteeExistInTeam["status"] === "accepted") {
                    setInviteeAccepted(true)
                    setMessage("You've already accepted the Inviter's team invitation!")
                } else {
                    setInviteeAccepted(false)
                    setMessage("")
                }
                let getTeamData = await getUserTeamsData(memberQueryUsers, teamMembersArr)
                let getUser = await getDoc(doc(db, "users", inviterId))
                getUser = getUser.exists ? { ...getUser.data(), uid: getUser.id } : {};
                setInviterUser({
                    ...getUser,
                    team: getTeamData
                })
            } else {
                setCheckInvitation(false)
                setMessage("Looks Like Invitation doesn't exists/expire/fake")
            }
            setPageLoading(false)
        } catch (error) {
            setPageLoading(false)
            console.log(error)
            setMessage(error.message)
        }
    }

    useEffect(() => {
        currentUser && !loading && checkVerifaication()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser])

    const isSameUser = currentUser.email === inviteeEmail;
    if (!isSameUser) return (
        <Navigate to={'/projects'} />
    )

    if (pageLoading) {
        return <CircularLoader />
    }

    return (
        <Container>
            <MuiGrid container spacing={2}>
                <Grid item xs={12} md={8}>
                    {!pageLoading && <StyledInvitationBox>
                        {inviterUser && checkInvitation ? <React.Fragment>
                            <Box sx={{ display: "flex", alignItems: "center", gap: "0.8rem" }}>
                                <Tooltip title={inviterUser?.email}>
                                    <Avatar sx={{ width: "70px", height: "70px" }} src={inviterUser?.photoURL} />
                                </Tooltip>
                                <ForwardIcon color='primary' width='2.5em' />
                                <Tooltip title="You">
                                    <Avatar sx={{ width: "70px", height: "70px" }} src={currentUser.photoURL} />
                                </Tooltip>
                            </Box>
                            <Box mt={1}>
                                <Typography display="inline-block" color="primary" textTransform="capitalize" fontWeight={700} mr={0.6}>
                                    {inviterUser?.displayName || inviterUser?.email.split("@")[0]}
                                </Typography>
                                <Typography variant='body1' fontWeight={700}>
                                    invited you to join his team "{inviterUser?.managerInfo?.teamName}""
                                </Typography>
                            </Box>
                            <Typography variant='body1'>What you can do after joining?</Typography>
                            <Stack component="ul" spacing={2}>
                                <Box component="li">
                                    <RadioButtonChecked />
                                    <Typography variant='body2'>Access owners project which he/she shares with you</Typography>
                                </Box>
                                <Box component="li">
                                    <RadioButtonChecked />
                                    <Typography variant='body2'>If you're solo, become a team member and won't see ads</Typography>
                                </Box>
                                <Box component="li">
                                    <RadioButtonChecked />
                                    <Typography variant='body2'>You can see others team members</Typography>
                                </Box>
                            </Stack>
                            <Box display="flex" flexDirection="row" gap={1} alignItems="stretch" width="100%" position="relative" mt={1}>
                                <Typography variant='body2' pt={0.5}>
                                    {!!inviterUser?.team.length ? "Join along with his others team members:" : "Accept the invite to become first team member"}
                                </Typography>
                                <Box sx={{ position: "relative", minWidth: "90px", minHeight: 44 }} mt={-0.5}>
                                    {inviterUser?.team?.filter((_v, i) => i < 3)?.map((v, i) =>
                                        <Box sx={{ position: "absolute", left: i === 0 ? 0 : (i * 25) }} key={v.uid}>
                                            <Tooltip title={v?.displayName || inviterUser?.email?.split("@")[0]} placement="top">
                                                <Avatar
                                                    key={v?.uid || v.email}
                                                    src={v?.photoURL || " "}
                                                    alt={v?.displayName?.toUpperCase() || v?.email?.toUpperCase()}
                                                    sx={{
                                                        border: "1px solid #FFFFFF",
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            {message &&
                                <Typography variant='body2' sx={{ fontWeight: "bold", color: theme => theme.palette.success.main }}>
                                    {message}
                                </Typography>
                            }
                            <Button
                                sx={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                size={"large"}
                                onClick={() => inviteeAccepted ? navigate("/dashboard") : addInvitee()}
                                disabled={loading}
                            >
                                {loading && <CircularProgress style={{ width: "20px", height: "20px", color: "inherit", marginRight: "10px" }} />}
                                <p>{inviteeAccepted ? "Go To Dashboard" : "Accept Invite"}</p>
                            </Button>
                        </React.Fragment> :
                            <Box className="fake">
                                <WarningIcon color='warning' sx={{ width: "4em", height: "4em" }} />
                                <Typography variant="h6" sx={{ textTransform: "capitalize", fontWeight: "bold", color: theme => theme.palette.error.main }}>
                                    {message}
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={_ => navigate("/dashboard")}
                                >
                                    Go To Dashboard
                                </Button>
                            </Box>
                        }
                    </StyledInvitationBox>
                    }
                </Grid>
            </MuiGrid>
        </Container>
    )
}

export default AcceptInvitation