import React from 'react'
import ReactDOM from 'react-dom/client'
import { AuthContextProvider } from './context/AuthContext'
import { TeamOwnersContextProvider } from './context/TeamOwnersContext'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MyTeamContextProvider } from './context/MyTeamContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <TeamOwnersContextProvider>
        <MyTeamContextProvider>
          <App />
        </MyTeamContextProvider>
      </TeamOwnersContextProvider>
    </AuthContextProvider>
  </React.StrictMode >
)

reportWebVitals()
