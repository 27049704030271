import React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'

const StyledWelcomedBox = styled(Box)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
    margin: "0rem auto",
    textAlign: "center",
    boxShadow: "rgb(0 0 0 / 5%) 0px 0px 5px 1px",
    "& .MuiButton-contained": {
        padding: "10px 2rem",
        [theme.breakpoints.down("sm")]: {
            padding: "5px 1rem",
        }
    },
}))

const WelcomeBox = ({ projectsExists, title }) => {

    const isSm = useMediaQuery('(max-width:650px)');

    return (
        <StyledWelcomedBox
            sx={{
                width: { xs: "auto", sm: "350px", md: "450px" },
            }}>
            <img src="/icons/document-report.svg" alt='document-report' />
            <Typography sx={{ color: "#000", fontWeight: 700, fontSize: 24, }}>
                {title || "Welcome to Cred Management!"}
            </Typography>
            <Typography variant='body2' py={2}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Commodo elit elementum nulla tempus, sagittis leo ut varius.</Typography>
            <Button
                variant='contained'
                color="primary"
                component={Link}
                to={projectsExists ? '/projects' : "/add-project"}
                size={isSm ? "small" : "large"}
            >
                {projectsExists ? "See Your Projects" : "Create Your First Project"}
            </Button>
        </StyledWelcomedBox>
    )
}

export default WelcomeBox