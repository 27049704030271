import * as React from "react"
import Avatar from "@mui/material/Avatar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Divider from "@mui/material/Divider"
import Logout from "@mui/icons-material/Logout"
import Settings from "@mui/icons-material/Settings"
import { styled } from '@mui/material/styles'
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAuthContext } from "../../hooks/useAuthContext"
import { Typography, useMediaQuery } from "@mui/material"


const StyledMenu = styled(Menu)(({ theme }) => ({
    ".MuiMenuItem-root": {
        // background: "red",
        minHeight: "48px",
        [theme.breakpoints.down("sm")]: {
            minHeight: "44px",
        }
    },
    "ul.innerRoutes": {
        display: "flex",
        flexDirection: "column",
        alignItems: "strecth",
        justifyContent: "center",
        padding: "10px",
        gap: "10px",
        "& > li": {
            "& > a": {
                width: "100%",
            }
        }
    }
}))

export default function UserMenu({
    open,
    anchorEl,
    handleClose,
    handleLogout,
    currentUser,
    middleRoutes,
    scrollToElement,
    showLogins,
    LoginRoutes
}) {

    const { authIsReady } = useAuthContext()
    const isMobile = useMediaQuery(`(max-width:${900}px)`);

    const location = useLocation()
    const navigate = useNavigate()

    return (
        <React.Fragment>
            <StyledMenu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        width: { xs: "230px" },
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* {authIsReady} */}

                {authIsReady &&
                    <MenuItem component={Link} to="/settings">
                        <Avatar
                            sx={{ width: 38, height: 38 }}
                            src={currentUser?.photoURL || ""}
                        />
                        <Typography sx={{ fontSize: "14px", fontWeight: "bold", textTransform: "capitalize", wordBreak: "break-all" }}>
                            {currentUser?.displayName || "Your Name"}
                        </Typography>
                        {/* <Divider /> */}
                    </MenuItem>

                }
                {!authIsReady && !showLogins && isMobile &&
                    // <>
                    LoginRoutes()
                    // < Divider />
                    // </>
                }
                {isMobile ?
                    middleRoutes
                        .filter(route => route.title === "Contact" && location.pathname === "/login" ? true : route.authenticated === authIsReady)
                        .map((item) => (
                            <MenuItem
                                key={item.title}
                                onClick={() => {
                                    if (item.to.includes("#")) {
                                        location.pathname !== "/" && navigate("/")
                                        return setTimeout(() => {
                                            scrollToElement(item.to)
                                        }, 20);
                                    }
                                    navigate(item.to)
                                }}
                            >
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                {item.title}
                            </MenuItem>
                        ))
                    : null
                }
                {authIsReady &&
                    <MenuItem onClick={()=>{navigate('/settings')}}>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        Settings
                    </MenuItem>
                }
                {authIsReady &&
                    <MenuItem onClick={handleLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                }
            </StyledMenu>
        </React.Fragment>
    )
}
