import React, { useEffect, useState } from "react"
import {
    Grid,
    Button,
    Stack,
    IconButton,
} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'

import DynamicTextField from "../components/DynamicTextField"
import ConfirmModal from "../../../../components/ConfirmationModal/ConfirmModal"
const DynamicFields = ({
    index,
    field,
    fields,
    clearErrors,
    control,
    setError,
    watch,
    append,
    remove,
    errors,
    stateValues
}) => {

    const [isKeyValDeleting, setIsKeyValDeleting] = useState(false)
    const [keyValueIndex, setKeyValueIndex] = useState(null)
    const [openDelete, setOpenDelete] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        setIsEdit(!(stateValues?.name && stateValues?.url && stateValues?.key && stateValues?.value))
    }, [stateValues])


    const handleDltKeyValue = (index) => {
        setOpenDelete(true)
        setKeyValueIndex(index)
    }

    const watchFields = watch("extraInfo")

    const handleFieldAdd = () => {
        let isEmpty = !watchFields[index].name || !watchFields[index].url || !watchFields[index].key || !watchFields[index].value
        if (isEmpty) {
            return Object.keys({ name: "", url: "", key: "", value: "" }).forEach(key => {
                !watchFields[index][key]
                    ? setError(
                        `extraInfo.${index}.${key}`,
                        { type: "required", message: 'Empty' },
                        { shouldFocus: true }
                    )
                    : clearErrors(`extraInfo.${index}.${key}`)
            })
        }
        setIsEdit(false)
        return append({ name: "", url: "", key: "", value: "" })
    }

    // const showEdit = useMemo(() => !!(!isEdit &&
    //     !!(watchFields[index].name && watchFields[index].url && watchFields[index].key && watchFields[index].value))
    //     , [isEdit])


    return (
        <Grid
            className="keyValuePair"
            key={field.id}
            container
            item
            gap={2}
            sx={{
                backgroundColor:
                    keyValueIndex === index && isKeyValDeleting
                        ? "#ff000012"
                        : "initial",
            }}
        >
            <Grid item xs={12} md={5.8}>
                <DynamicTextField
                    label="Name"
                    name={`extraInfo.${index}.name`}
                    clearErrors={clearErrors}
                    control={control}
                    disabled={!isEdit}
                />
            </Grid>
            <Grid item xs={12} md={5.8}>
                <DynamicTextField
                    label="Url"
                    name={`extraInfo.${index}.url`}
                    placeholder="Type or paste your url"
                    clearErrors={clearErrors}
                    disabled={!isEdit}
                    control={control}
                />
            </Grid>
            <Grid item xs={12} md={5.8}>
                <DynamicTextField
                    label="Key"
                    name={`extraInfo.${index}.key`}
                    clearErrors={clearErrors}
                    control={control}
                    disabled={!isEdit}
                />
            </Grid>
            <Grid item xs={12} md={5.8}>
                <DynamicTextField
                    label="Value"
                    name={`extraInfo.${index}.value`}
                    clearErrors={clearErrors}
                    control={control}
                    disabled={!isEdit}
                />
            </Grid>
            <Grid item xs={12} className="action_btns">
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                    {(index !== fields.length - 1 || (!isEdit)) && (
                        <IconButton
                            size='small'
                            color="info"
                            onClick={() => setIsEdit(val => !val)}
                        >
                            {isEdit ? <SaveIcon /> : <EditIcon />}
                        </IconButton>
                    )}
                    {fields.length > 1 && index === fields.length - 1 && (
                        <IconButton
                            size='small'
                            color="error"
                            onClick={() => handleDltKeyValue(index)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                    {index !== fields.length - 1 ?
                        <IconButton
                            size='small'
                            color={"error"}
                            onClick={() => handleDltKeyValue(index)}
                        >
                            <DeleteIcon />
                        </IconButton> :
                        <Button
                            color={"primary"}
                            variant="text"
                            onClick={() => handleFieldAdd(index)}
                            startIcon={<AddIcon />}
                        >
                            Add
                        </Button>
                    }
                </Stack>
            </Grid>
            <ConfirmModal
                openDelete={openDelete}
                handleDeleteClose={() => {
                    setOpenDelete(false)
                    setTimeout(() => {
                        setKeyValueIndex(null)
                    }, 400)
                }}
                onClick={() => {
                    setIsKeyValDeleting(true)
                    setTimeout(() => {
                        setIsKeyValDeleting(false)
                        remove(keyValueIndex)
                    }, 400)
                }}
                title="Are You sure? this will delete the key value pair"
            />
        </Grid>
    )
}

export default DynamicFields