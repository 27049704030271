import React, { useEffect, useState } from 'react'
import { Avatar, Box, Button, Grid, IconButton, styled, Typography, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BasicMenu from '../../components/BasicMenu/BasicMenu';
import { useAuthContext } from '../../hooks/useAuthContext';
import { addDoc, arrayRemove, collection, doc, getDocs, query, serverTimestamp, updateDoc, where, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import ConfirmModal from '../../components/ConfirmationModal/ConfirmModal';
import EditMemberModal from './components/EditMemberModal';
import { useSnackbar } from 'notistack';
import SendInvitation from './components/SendInvitation';
import CircularLoader from '../../components/CircularLoader/CircularLoader';
import { useMyTeamContext } from '../../hooks/useMyTeamContext';

const MainContainer = styled(Box)(() => ({
    minHeight: "85vh",
    width: "100%",
    "& .MuiOutlinedInput-root": {
        paddingLeft: 8,
        "& input": {
            padding: "11px 8px"
        }
    },
    "& .MuiButton-contained": {
        padding: "10px 2rem"
    }
}))

const TeamMemberContariner = styled(Box, {
    shouldForwardProp: prop => prop !== "status"
})(({ theme, status }) => ({
    minHeight: 200,
    width: "100%",
    height: "100%",
    position: "relative",
    padding: theme.spacing(2, 0),
    backgroundColor: "#FFFFFF",
    boxShadow: "rgb(0 0 0 / 5%) 0px 0px 5px 1px",
    "& .projectTitle": {
        textTransform: "capitalize", paddingLeft: theme.spacing(3), color: "#000", fontWeight: 500, fontSize: 16, borderLeft: "2px solid #4A3AFF", flex: 1
    },

    "& .first_box": {
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        borderLeft: "2px solid #4A3AFF",
        position: "relative",
        gap: "10px",
        "& > .avatar": {
            position: "relative",
            width: "fit-content",
            borderRadius: "50%",
            "& > .MuiAvatar-root": {
                width: 83,
                height: 83,
                [theme.breakpoints.down("md")]: {
                    width: 70,
                    height: 70,
                },
                [theme.breakpoints.down("sm")]: {
                    width: 60,
                    height: 60,
                }
            },
            "& > .status_dot": {
                border: "1px solid #FFF",
                width: 14,
                height: 14,
                [theme.breakpoints.down("sm")]: {
                    width: 12,
                    height: 12,
                },
                backgroundColor: status === "pending" ? theme.palette.primary.warning : "#12B347",
                position: "absolute",
                borderRadius: "50%",
                left: "74%",
                bottom: "2%"
            }
        },
        "& > .txt": {
            flex: 1,
            paddingRight: "8px",
            "& > .MuiTypography-root": {
                overflowWrap: "anywhere",
                "&.name": {
                    fontWeight: 700,
                    color: "#000",
                    textTransform: "capitalize",
                    maxWidth: "86.5%",
                    lineHeight: "22px",
                }
            },
        }
    }
}))

const InviteTeam = ({ userRole }) => {

    const { user } = useAuthContext()
    const { palette } = useTheme()
    const { isTeamLoading, myTeam, error: teamError } = useMyTeamContext()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [sendInvite, setSendInvite] = useState(true)
    const { state } = useLocation()
    const [organizedTeam, setOrganizedTeam] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMember, setSelectedMember] = useState("")
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    useEffect(() => {
        if (userRole !== "team") {
            navigate("/upgrade", { replace: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole])

    useEffect(() => {
        teamError && enqueueSnackbar(teamError, { variant: "error" })
    }, [enqueueSnackbar, teamError])

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpenDelete(false);
        setOpenEdit(false);
    };

    useEffect(() => {
        const compare = (a, b) => {
            let checkA = a.displayName ? "displayName" : "email"
            let checkB = b.displayName ? "displayName" : "email"
            if (a[checkA] < b[checkB]) {
                return -1;
            }
            if (a[checkA] > b[checkB]) {
                return 1;
            }
            return 0;
        }

        if (myTeam && myTeam.length > 0) {
            let pendingTeam = myTeam.filter((v) => v.status === "pending")
            let addedTeam = myTeam.filter((v) => v.status === "accepted")

            setOrganizedTeam([...pendingTeam.sort(compare), ...addedTeam.sort(compare)])
        } else {
            setOrganizedTeam([])
        }
    }, [myTeam])

    const handleMemberDlt = async (emailId) => {
        handleDeleteClose()
        handleClose()
        try {
            const memberTeamRef = doc(db, 'users', user.uid, "team", emailId)
            await deleteDoc(memberTeamRef)

            const memberQuery = query(collection(db, "users"), where("email", "==", emailId));
            let receiverUser = await getDocs(memberQuery)
            if (!receiverUser.empty) {
                receiverUser.forEach(async recvrUser => {
                    const userProjctsRef = query(
                        collection(db, "projects"),
                        where("uid", "==", user.uid),
                        where("team", "array-contains", recvrUser.id)
                    );
                    const getUserProjects = await getDocs(userProjctsRef)
                    getUserProjects.forEach(async projDoc => {
                        await updateDoc(doc(db, 'projects', projDoc.id), {
                            team: arrayRemove(recvrUser.id)
                        })
                    })
                    const payloadNoti = {
                        title: `Removed from the team`,
                        message: `You are no longer in ${user.displayName}'s team, now you won't be able to access his projects anymore`,
                        sender_id: user.uid,
                        receiver_id: recvrUser.id,
                        seen: false,
                        createdAt: serverTimestamp(),
                    }
                    const notiRef = collection(db, 'notifications')
                    await addDoc(notiRef, payloadNoti)
                })
            }
        } catch (err) {
            console.log(err)
        }
        setSelectedMember("")
    }



    const handleDeleteOpen = () => setOpenDelete(true);
    const handleDeleteClose = () => setOpenDelete(false);

    const handleEditOpen = () => setOpenEdit(true);
    const handleEditClose = () => setOpenEdit(false);

    const options = [
        {
            name: "Edit",
            icon: <img src='/icons/_edit.png' alt='edit' />,
            onClick: () => handleEditOpen(),
        },
        {
            name: "Delete",
            icon: <img src='/icons/dlt.svg' alt='delete' />,
            sx: { color: "#FF3559" },
            onClick: () => handleDeleteOpen()
        },
    ]

    const convertSinceDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ]
        const getDate = new Date(date)
        return `Since ${monthNames[getDate.getMonth()]} ${getDate.getFullYear()}`
    }

    if (isTeamLoading) {
        return <CircularLoader />
    }

    return (
        <MainContainer sx={{ py: "30px", px: 3, bgcolor: "#F8FAFC" }}>
            <Box display={"flex"} mb={{ xs: 3, sm: 0 }}>
                <Typography sx={{ color: "#000", fontWeight: 700, fontSize: 24, mb: { xs: 5, md: 3 }, flex: 1 }}>
                    Team <span style={{ color: palette.primary.main }}>{user?.managerInfo && user.managerInfo?.teamName}</span>
                </Typography>
                <Box sx={{ display: { xs: "flex", sm: "contents", lg: "flex" }, flexDirection: { xs: "column-reverse", sm: "row" }, alignItems: "stretch", flex: 0.8 }}>
                    <Button variant="contained" sx={{ whiteSpace: "nowrap", height: "max-content", ml: "auto", mb: { xs: "4rem" } }} startIcon={<img src='/icons/mail.svg' alt='invite' />} onClick={() => setSendInvite(!sendInvite)} >Invite</Button>
                </Box>
            </Box>
            {(organizedTeam?.length !== 0 && sendInvite) ?
                <Grid container spacing={2}>
                    {organizedTeam?.map((team, idx) =>
                        <Grid key={idx} item xs={12} sm={6} md={4} lg={3} sx={{ boxSizing: "border-box", }}>
                            <TeamMemberContariner status={team.status}>
                                <Box sx={{ height: 9 }} />
                                <Box className='first_box'>
                                    <Box className='avatar'>
                                        <Avatar src={team?.photoURL || " "} alt={team?.displayName?.toUpperCase() || team?.email?.toUpperCase()} />
                                        <Box className='status_dot' component={"span"} ></Box>
                                    </Box>
                                    <Box className="txt">
                                        <Typography className='name' variant='body1'>{team?.displayName || team?.email?.split("@")[0]}</Typography>
                                        <Typography variant='body2' pr={2}>{team.email}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ position: "absolute", right: 3, top: 2 }}>
                                    <IconButton aria-label="more"
                                        onClick={(e) => {
                                            setSelectedMember(team.email)
                                            handleClick(e)
                                        }}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </Box>
                                <Box sx={{ px: 3, py: 2 }}>
                                    <Box display={"flex"}>
                                        <Box position={"relative"} sx={{
                                            width: 24,
                                            height: 24,
                                            "&>img": {
                                                position: "absolute",
                                            }
                                        }}>
                                            <img src='/icons/Ellipse 180-1.svg' alt='' />
                                            <img src='/icons/Star 1.svg' alt='' style={{ left: 6, top: 6, }} />
                                        </Box>
                                        <Typography variant='body1' pl={2}>{team?.position || "NA"}</Typography>
                                    </Box>
                                    <Box display={"flex"} py={2}>
                                        <Box position={"relative"} sx={{
                                            width: 24,
                                            height: 24,
                                            "&>img": {
                                                position: "absolute",
                                                m: "auto",
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                top: 0,
                                            }
                                        }}>
                                            <img src='/icons/Ellipse 180-1.svg' alt='' />
                                            <img src='/icons/call.svg' alt='' />
                                        </Box>
                                        <Typography variant='body1' pl={2}>{team?.phone || "NA"}</Typography>
                                    </Box>
                                    <Box display={"flex"}>
                                        <Box position={"relative"} sx={{
                                            width: 24,
                                            height: 24,
                                            "&>img": {
                                                position: "absolute",
                                                m: "auto",
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                top: 0,
                                            }
                                        }}>
                                            <img src='/icons/Ellipse 180-1.svg' alt='' />
                                            <img src='/icons/calender.svg' alt='' />
                                        </Box>
                                        <Typography variant='body1' pl={2}>{team?.sinceDate ? convertSinceDate(team.sinceDate) : "NA"}</Typography>
                                    </Box>
                                </Box>
                            </TeamMemberContariner>
                        </Grid>
                    )}
                </Grid> :
                <SendInvitation
                    state={state}
                    myTeam={myTeam || []}
                    sendInvite={sendInvite}
                    setSendInvite={setSendInvite}
                    currentUser={user}
                />
            }
            <BasicMenu
                open={open}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleClose={handleClose}
                options={options}
                handleDeleteOpen={handleDeleteOpen}
            />
            <EditMemberModal
                openEdit={openEdit}
                handleEditClose={() => {
                    handleEditClose()
                    handleClose()
                }}
                selectedMember={selectedMember}
                teamMemberToEdit={myTeam && myTeam.find(member => member.email === selectedMember)}
            />
            <ConfirmModal
                openDelete={openDelete}
                handleDeleteClose={handleDeleteClose}
                onClick={() => handleMemberDlt(selectedMember)}
            />

        </MainContainer>
    )
}

export default InviteTeam