import { useNavigate, useParams } from 'react-router-dom'
import { db } from '../../../../firebase/config'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState, forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Slide, Modal, Button, CircularProgress } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BasicMenu from '../../../../components/BasicMenu/BasicMenu'
import { useSnackbar } from 'notistack'
import { getStorage, ref, deleteObject } from "firebase/storage"


const DialogFiles = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        "& .file-box": {
            padding: theme.spacing(2, 0),
            boxShadow: "rgb(0 0 0 / 5%) 0px 0px 5px 1px",
            backgroundColor: "rgb(0 0 0 / 4%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            "& img": {
                cursor: "pointer",
            },
            "& button": {
                position: "absolute",
                opacity: 0,
                top: "3%",
                right: "0%",
                padding: "4px",
                transition: "all 0.4s ease",
            },
            "&:hover": {
                "& button": {
                    opacity: 1
                }
            }
        },

    }
}));

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "2px",
    boxShadow: 24,
    py: 2,
    px: 3,
    outline: "none",
    "& div": {
        display: "flex",
        justifyContent: "space-around",
        "& button": {
            mt: "1rem",
            width: 100
        }
    }
};

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const FileModal = ({
    project,
    user,
    openFile,
    setFileOpen,
}) => {

    const { enqueueSnackbar } = useSnackbar()
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [dltLoading, setDltLoading] = useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setSelectedFileUrl(null)
    };


    const handleDeleteOpen = () => setOpenDelete(true);
    const handleDeleteClose = () => setOpenDelete(false);

    const handleCloseModal = () => {
        handleDeleteClose()
        handleClose()
    }

    const options = [
        {
            name: "Delete",
            icon: <img src='/icons/dlt.svg' alt='edit' />,
            disabledInvite: project.uid !== user.uid,
            sx: { color: "#FF3559" },
            onClick: () => handleDeleteOpen()
        },
    ]

    const handleDeleteFile = async () => {
        try {
            setDltLoading(true)
            const storageRefa = ref(getStorage(), selectedFileUrl)
            await deleteObject(storageRefa)
            const firestoreRef = doc(db, "projects", project.id)
            await updateDoc(firestoreRef, {
                files: project.files.filter(file => file.url !== selectedFileUrl)
            })
            enqueueSnackbar("File Deleted!", { variant: "success" })
            handleCloseModal(false)
            setDltLoading(false)
        } catch (error) {
            setDltLoading(false)
            enqueueSnackbar(error.message, { variant: "error" })
        }
    }

    return (
        <Box>
            <DialogFiles
                open={openFile}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setFileOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                className='file-dialog'
                sx={{
                    "& .MuiDialog-paper": {
                        minWidth: "340px",
                        p: 1,
                        borderRadius: "2px"
                    }
                }}
            >
                <DialogContent sx={{
                    overflow: "hidden"
                }}>
                    <Box sx={{
                        width: { md: "600px" },
                    }}>
                        <Box display={"flex"}>
                            <Typography variant="h6" color="initial" sx={{ flexGrow: 0.9, visibility: "hidden" }}>{project?.fileDescription}</Typography>
                            <IconButton aria-label="" onClick={() => setFileOpen(false)}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        </Box>
                        <Box display={"flex"} overflow="auto" pb={3} pr={5} gap="1rem">
                            {project?.files && project?.files?.length !== 0 ? project?.files?.map((v, i) => {
                                return (
                                    <Box key={i} href={v.url} target="_blank" rel="noreferrer" className='file-box'>
                                        <Typography variant="body2" color="initial" sx={{ px: 3, pb: 1, fontSize: 12 }}>
                                            {v.metadata?.slice(0, v.metadata.lastIndexOf("T"))}
                                        </Typography>
                                        <img alt={v.url} src="/image.png" width={"120"} onClick={() => window.open(v.url, '_blank', 'noopener,noreferrer')} />
                                        {project.uid === user.uid &&
                                            <IconButton aria-label="more" onClick={(e) => {
                                                handleClick(e)
                                                setSelectedFileUrl(v.url)
                                            }}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        }

                                    </Box>
                                )
                            }) : "No file exists!"}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    Files : {project?.files ? project?.files?.length : "0"}
                </DialogActions>
            </DialogFiles>
            <Modal
                open={openDelete}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6">
                        Are you sure? this will delete it permanently!
                    </Typography>
                    <Box>
                        <Button
                            variant="outlined"
                            onClick={handleCloseModal}
                        >
                            No
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleDeleteFile}
                            disabled={dltLoading}
                        >
                            {dltLoading && (
                                <CircularProgress
                                    style={{ width: "20px", height: "20px", color: "#fff" }}
                                />
                            )}
                            Yes
                        </Button>
                    </Box>
                </Box>
            </Modal>
            <BasicMenu
                open={open}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleClose={handleClose}
                options={options}
            />
        </Box>
    )
}

export default FileModal