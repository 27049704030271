import { Box, Typography } from '@mui/material'
import React, { useEffect } from 'react'

function ContactUs() {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'auto' })
	}, [])
	return (
		<Box sx={{padding:"15px",textAlign:"center"}} >
			<Typography variant='h3' sx={{color:"black",marginBottom:"10px"}}>Contact Us</Typography>
			<Typography variant='body1' >
				Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
				repellendus minus obcaecati at saepe sunt, suscipit consectetur
				id officiis ipsam corporis eligendi doloremque? Alias enim saepe
				quo, facilis provident quos similique accusantium ullam tempora
				maxime cum non reprehenderit, sunt quaerat voluptatibus id
				eligendi dolorem minus.
			</Typography>
		</Box>
	)
}

export default ContactUs
