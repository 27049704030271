import React, { useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { styled } from '@mui/material/styles'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import carouselImg1 from "../../images/carousel1.png"


const CarouselContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: "-18px",

    "& .carousel-root": {
        transition: "all 0.4s ease",
        "& .MuiButtonBase-root": {
            background: "hsl(0deg 0% 0% / 40%)",
            transition: "all 0.4s ease",
            opacity: "0",
        },
        "&:hover": {
            "& .MuiButtonBase-root": {
                opacity: "1",
                // background: "hsl(0deg 0% 0% / 30%)",
            }
        },
        "li.slide > div": {
            height: "550px",
            position: "relative",
            "& > img": {
                height: "100%",
                filter: "brightness(0.85)"
            },
            [theme.breakpoints.down("md")]: {
                height: "360px",
            },
            [theme.breakpoints.down("sm")]: {
                height: "260px"
            },
            "& > .textStack": {
                height: "100%",
                width: "100%",
                padding: "1.5rem",
                position: "absolute",
                top: "0%",
                bottom: "0%",
                "& > .MuiTypography-root": {
                    textShadow: "3px 4px 7px rgba(81,67,21,0.8);"
                }
            }
        }
    },


}))

const CustomArrow = styled(IconButton, {
    shouldForwardProp: prop => prop !== "next"
})(({ next }) => ({
    position: "absolute",
    top: 0,
    bottom: 0,
    background: "hsl(0deg 0% 0% / 26%)",
    zIndex: 1,
    height: "fit-content",
    marginTop: "auto",
    marginBottom: "auto",
    padding: "10px",
    color: "rgb(247 247 248 / 74%)",
    transition: "all 0.4s ease",
    "&:hover": {
        background: "hsl(0deg 0% 0% / 26%)",
    },
    "&.prev": {
        left: 0,
        marginLeft: "10px",
    },
    "&.next": {
        right: 0,
        marginRight: "10px",
    }
}))

const ImageCarousel = () => {

    const [currentSlide, setCurrentSlide] = useState(0)

    const carouselData = [
        {
            src: carouselImg1,
            title: "Take your team management to next level",
            desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
        },
        {
            src: "https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            title: "Who thought sharing creds for your project would be easy!",
            desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
        },
        {
            src: "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
        },
        {
            src: "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
            title: "Build and do project managment with few pennies",
            desc: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
        }
    ]

    const updateCurrentSlide = (index) => {
        if (currentSlide !== index) {
            setCurrentSlide(index);
        }
    };

    return (
        <CarouselContainer>
            <Carousel
                // autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                showArrows={true}
                showIndicators={true}
                showStatus={false}
                selectedItem={currentSlide}
                onChange={updateCurrentSlide}
                renderArrowPrev={(onClick, hasPrev) => (
                    <CustomArrow onClick={onClick} className="prev">
                        <ArrowBack />
                    </CustomArrow>
                )}
                renderArrowNext={(onClick, hasNext) => (
                    <CustomArrow onClick={onClick} className="next">
                        <ArrowForward />
                    </CustomArrow>
                )}
                renderIndicator={(onClick, isSelected, idx) => (
                    <Box
                        component={"li"}
                        onClick={onClick}
                        sx={{
                            cursor: 'pointer',
                            display: "inline-block",
                            backgroundColor: "#fff",
                            borderRadius: "50%",
                            border: "1px solid #fff",
                            opacity: isSelected ? 1 : 0.5,
                            p: 0.7,
                            mr: 1,
                        }}
                    />
                )}
            >
                {carouselData.map((obj, idx) => (
                    <Box key={idx}>
                        <img src={obj.src} alt="Team Mangment Meeting Pic" />
                        <Stack
                            className='textStack'
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography
                                variant='h2'
                                color="#fff"
                                maxWidth={700}
                                sx={{
                                    fontSize: { xs: 30, sm: 38, md: 44, lg: 48 },
                                    lineHeight: { xs: "32px", sm: "40px", md: "50px" }
                                }}
                            >
                                {obj.title}
                            </Typography>
                            <Typography
                                variant='h5'
                                color="#fff"
                                maxWidth={750}
                                px={2}
                                fontWeight="400"
                                lineHeight="25px"
                                sx={{
                                    fontSize: { xs: 14, sm: 18, md: 22 },
                                    lineHeight: { xs: "20px", sm: "22px", md: "25px" }
                                }}
                            >
                                {obj.desc}
                            </Typography>
                        </Stack>
                    </Box>
                ))}
            </Carousel>
        </CarouselContainer>
    )
}

export default ImageCarousel