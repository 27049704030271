import React, { useState } from 'react'
import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import emailjs from '@emailjs/browser';
import { useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { addDoc, collection, doc, getDocs, query, serverTimestamp, setDoc, where } from 'firebase/firestore';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { auth, db } from '../../../firebase/config';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const StyledInvitationBox = styled(Box)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    boxShadow: "rgb(0 0 0 / 5%) 0px 0px 5px 1px",
    position: "relative",
    padding: theme.spacing(3),
    margin: "auto",
    textAlign: "center",
    "& .back": {
        position: "absolute",
        left: 10,
        top: 10
    },
    "& .MuiFormControl-root": {
        "& > .MuiFormHelperText-root": {
            color: "red",
            marginLeft: "2px",
        },
    },

}))

const SendInvitation = ({
    setSendInvite,
    myTeam,
    state,
    currentUser,
}) => {

    const {
        register,
        handleSubmit,
        setError,
        reset,
        clearErrors,
        setValue,
        watch,
        formState: { errors },

    } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            email: ""
        }
    })


    const { user } = useAuthContext()
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
    const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
    const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

    const InputEndAdornment = (
        <InputAdornment position="end" sx={{ cursor: "pointer" }}>
            {watch("email") &&
                <IconButton onClick={() => {
                    setValue("email", "");
                    clearErrors("email")
                }}
                    sx={{ transition: "display 0.2s ease", display: "flex" }}
                >
                    <CloseIcon />
                </IconButton>
            }
        </InputAdornment>
    );

    const onSubmit = async ({ email }) => {
        clearErrors("email");

        const isExistInTeam = myTeam.filter(val => val.email === email)

        if (isExistInTeam.length > 0 || user.email === email) {
            return setError('email', {
                type: 'custom',
                message: user.email === email ? "You can't send invitation to your own email" : "This user is already in your team"
            });
        }
        setLoading(true)

        try {
            const date = new Date()
            const isExistInApp = await fetchSignInMethodsForEmail(auth, email)
            const message = `${window.location.origin}/teamInvite/${user.uid}/${email}${!isExistInApp?.length ? "?redirectTo=register" : ""}`
            const templateParams = {
                to_email: email,
                from_email: user.email,
                message: message,
                from_name: user.displayName,
            };

            await emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, PUBLIC_KEY)

            let memberData = { email: email, status: "pending", sinceDate: date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() }
            const memberTeamRef = doc(db, 'users', user.uid, "team", email)
            await setDoc(memberTeamRef, memberData)

            const usersRef = collection(db, "users");
            const q = query(usersRef, where("email", "==", email));
            let receiverUser = await getDocs(q)

            if (!receiverUser.empty) {
                receiverUser.forEach(async doc => {
                    const payloadNoti = {
                        title: `Team Invitation`,
                        message: `You get an inviation to join ${currentUser.displayName}'s team check your email`,
                        sender_id: user.uid,
                        receiver_id: doc.id,
                        seen: false,
                        link: `/teamInvite/${user.uid}/${email}${!isExistInApp?.length ? "?redirectTo=register" : ""}`,
                        createdAt: serverTimestamp(),
                    }
                    const notiRef = collection(db, 'notifications')
                    await addDoc(notiRef, payloadNoti)
                })
            }
            enqueueSnackbar("Invite Sent, he will receive notification and mail", { variant: "success" })
            setSendInvite(true)
            reset();
        } catch (err) {
            enqueueSnackbar(err.message, { variant: "error" })
        }
        setLoading(false)
    }

    return (
        <StyledInvitationBox
            className='send-invite'
            sx={{
                width: { xs: "auto", sm: "380px", md: "460px" },
            }}
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
        >
            {!!myTeam.length &&
                <IconButton className='back' onClick={() => setSendInvite(true)}>
                    <ArrowBackIcon />
                </IconButton>
            }
            <img src="/icons/document-report-1.svg" alt='document-report' />
            <Typography sx={{ color: "#000", fontWeight: 700, fontSize: 24, }}>+ Invite Your Team</Typography>
            {!myTeam.length &&
                <Typography variant='body2' mt={1} fontWeight="500">
                    You Don't have team. Make one by start inviting!
                </Typography>
            }
            <Typography variant='body2' py={!myTeam.length ? 0 : 2} mb={!myTeam.length ? 2 : 1}>
                Your team member will recieve invitation link via email.
            </Typography>
            <TextField
                variant='outlined'
                placeholder='Email'
                fullWidth
                type={"email"}
                InputProps={{
                    endAdornment: InputEndAdornment,
                    startAdornment: (
                        <IconButton>
                            <img src='../icons/invite-dark.svg' alt='invite' />
                        </IconButton>
                    )
                }}
                autoComplete="off"
                error={!!errors.email}
                helperText={errors?.email && errors.email.message}
                name="email"
                {...register("email", {
                    required: "Please enter an email address",
                    pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Invalid email address"
                    }
                })}
                onChange={e => {
                    clearErrors("email");
                    register("email").onChange(e)
                }}
            />
            <Button
                type='submit'
                variant='contained'
                disabled={loading}
                color="primary" sx={{ mt: 2 }}
                startIcon={loading && (
                    <CircularProgress
                        style={{ width: "20px", height: "20px", color: "#fff" }}
                    />
                )}
            >
                Send Invitation
            </Button>
        </StyledInvitationBox>
    )
}

export default SendInvitation