import { Link, useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase/config";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Typography,
  Container,
  Grid,
  InputAdornment,
  IconButton,
  CircularProgress,
  OutlinedInput,
  FormControl,
  useMediaQuery,
  Avatar,
  Tooltip,
  Stack,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useAuthContext } from "../../../hooks/useAuthContext";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ArrowBackIcon from "@mui/icons-material/Close";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import FileModal from "./components/FileModal";
import { useMyTeamContext } from "../../../hooks/useMyTeamContext";
import BasicMenu from "../../../components/BasicMenu/BasicMenu";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PublicIcon from '@mui/icons-material/Public';
import { useSnackbar } from "notistack";

const MuiGrid = styled(Grid)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  // border: "1px solid",
  boxSizing: "border-box",
  padding: "20px 0px",
  "&>.MuiGrid-item": {
    height: "100%",
    boxSizing: "border-box",
  },
}));

const ProjectDetailCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== "loading" && prop !== "error",
})(({ theme, loading, error }) => ({
  height: "100%",
  minHeight: error ? "auto" : "70vh",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  background: "#FFF",
  boxShadow: "rgb(0 0 0 / 5%) 0px 0px 5px 1px",
  borderRadius: "2px",
  padding: "20px 30px",
  // boxShadow: "0px 3px 11px rgb(0 0 0 / 6%), 0px 10px 15px rgb(0 0 0 / 3%)",

  [theme.breakpoints.down("sm")]: {
    padding: "10px",
  },
  ...(loading && {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  }),
  "& >*": {
    marginTop: "10px",
    marginBottom: "15px",
  },
  "& > div .heading": {
    textAlign: "center",
    color: "#4F4F4F",
    fontWeight: "bold",
    marginLeft: "20px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px",
    },
    "&.error": {
      textTransform: "unset",
      fontSize: "20px",
      color: theme.palette.error.main,
      marginLeft: "0px",
      background: "pink",
      border: "1px solid red",
      borderRadius: "2px",
      padding: "8px",
    },
  },
}));

const ProjectContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSm",
})(({ theme, urlExist, isSm }) => ({
  position: "relative",
  background: "#FFFFFF",
  borderRadius: "2px",
  minHeight: isSm ? "auto" : "200px",
  maxHeight: isSm ? "350px" : "auto",
  overflowY: "auto",
  flex: 1,
  ".MuiFormControl-root": {
    width: "100%",
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
      "& input": {
        fontSize: "12px",
      },
    },
  },
  "& > div > *": {
    paddingLeft: "20px",
    paddingRight: "20px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "8px",
    },
  },
  "& > div > .right.info, .left.info": {
    minHeight: "90px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  "& > div > .left": {
    // border: "1px solid red",
    "&.nameKey-box": {
      padding: "5px 12px",
      marginBottom: "8px",
      justifyContent: "center",
    },
    overflowWrap: "anywhere",
    zIndex: 5,
    // background: "#E0E0E0",
    "&:first-of-type": {
      paddingTop: "15px",
    },
    "&:last-of-type": {
      paddingBottom: "15px",
    },
    "& > p": {
      fontSize: "16px",
      color: "#4a3affde",
      fontWeight: "bold",
      marginBottom: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },
  "& > div > .right": {
    // border: "1px solid green",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    "&.valueKey-box": {
      padding: "5px 12px",
      marginBottom: "8px",
      justifyContent: "center",
    },
    "&:first-of-type": {
      paddingTop: "15px",
    },
    "& > p": {
      fontSize: "16px",
      color: "rgb(0 0 0 / 60%)",
      marginBottom: "10px",

      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      "&.key": {
        overflowWrap: "anywhere",
        marginBottom: "0px",
      },
    },
  },
}));

const NameKeyContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "urlExist",
})(({ theme, urlExist }) => ({
  display: "flex",
  gap: "8px",
  width: "100%",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    gap: "5px",
    justifyContent: "center",
  },

  "& .icon-box": {
    // width: "35px",
    // height: "35px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "5px",
      flexWrap: "wrap",
    },
    "& > .MuiAvatar-root": {
      cursor: urlExist ? "pointer" : "auto",
      width: "35px",
      height: "35px",
      userSelect: "none",
      [theme.breakpoints.down("sm")]: {
        width: "28px",
        height: "28px",
      },
      "& > img": {
        userSelect: "none",
      },
    },
    "& .MuiTypography-root.name": {
      textAlign: "center",
      textTransform: "capitalize",
      fontSize: "0.75rem",
      fontWeight: "bold",
    },
  },
  "& .nameKey-box": {
    flex: 1,
    flexDirection: "column",
    "& .MuiTypography-root": {
      overflowWrap: "anywhere",
    },
    "& .MuiTypography-root.name": {
      textTransform: "capitalize",
      fontSize: "0.80rem",
      fontWeight: "bold",
    },
    "& .MuiTypography-root.key": {
      fontSize: "0.70rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.80rem",
      },
    },
  },
}));
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#4A3AFF",
    fontSize: "15px",
    border: "1px solid #4A3AFF",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#4A3AFF",
  },
});
const ProjectDetails = () => {
  const isSm = useMediaQuery("(max-width:570px)");
  const { id } = useParams();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const { myTeam } = useMyTeamContext();
  const [error, setError] = useState("");

  const [openFile, setFileOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    const docRef = doc(db, "projects", id);
    const unsub = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = { ...docSnap.data(), id: docSnap.id };
        if (
          data.uid === user.uid ||
          (Array.isArray(data.team) &&
            data.team.includes(user.uid) &&
            data.project_type === "team")
        ) {
          setProject([data]);
          setError("");
        } else {
          setProject([]);
          setError("Project not found or you don't have access");
        }
        setLoading(false);
      } else {
        setProject([]);
        setError("Project not found or you don't have access");
        setLoading(false);
      }
    });
    return () => unsub();
  }, [id, user]);

  const getHostnameFromRegex = (url) => {
    // run against regex
    const matches = url?.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
    // extract hostname (will be null if no match is found)
    return matches && matches[1];
  };

  const KeyValueComponent = ({ v, i }) => {
    const [hiddenState, setHiddenState] = useState(false);
    const [textCopied, setTextCopied] = useState(false);
    const isSm = useMediaQuery("(max-width:600px)");
    const [anchorEl, setAnchorEl] = useState(null);
    const { enqueueSnackbar } = useSnackbar(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const options = [
      {
        name: "Copy key",
        icon: <ContentCopyIcon fontSize="14px" />,
        sx: { padding: '4px 12px 4px 6px', '& p': { fontSize: '14px' } },
        onClick: () => {
          navigator.clipboard.writeText(v.key);
          setTextCopied(true);
          enqueueSnackbar("Key Copied!", { variant: "success" })
        },
      },
      {
        name: "Visit Url",
        icon: <PublicIcon fontSize="14px" />,
        sx: { padding: '4px 12px 4px 6px', '& p': { fontSize: '14px' } },
        onClick: () => {
          window.open(v?.url, "_blank");
        }
      },
    ]

    return (
      <React.Fragment key={i}>
        <Grid
          item
          xs={4}
          className="left nameKey-box"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <NameKeyContainer urlExist={!!v.url}>
            <Box className="icon-box">
              <Avatar
                alt="Remy Sharp"
                src={`https://icons.duckduckgo.com/ip2/${getHostnameFromRegex(
                  v.url
                )}.ico`}
                onClick={() => v.url && window.open(v.url, "_blank")}
              />
              {isSm && <Typography className="name">{v.name}</Typography>}
            </Box>
            {!isSm && (
              <Stack direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'} width={'100%'} spacing={1}>
                <Box className="nameKey-box" position="relative">
                  <Typography className="name">{v.name}</Typography>
                  <Typography className="key">{v.key}</Typography>
                </Box>
                <IconButton
                  aria-label="more"
                  size="small"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
              </Stack>
            )}
          </NameKeyContainer>
          <BasicMenu
            open={open}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            handleClose={handleClose}
            options={options}
            iconProps={{
              size: 'small'
            }}
          />
        </Grid>
        <Grid item xs={8} className="right valueKey-box">
          <FormControl
            sx={{ m: 1, width: "25ch", display: "flex", flexDirection: "row" }}
            variant="outlined"
          >
            <OutlinedInput
              sx={{ width: "90%" }}
              size="small"
              disabled
              type={hiddenState ? "text" : "password"}
              value={v.value}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{ zIndex: 9999, position: "relative" }}
                    aria-label="toggle password visibility"
                    onClick={() => setHiddenState(!hiddenState)}
                    edge="end"
                  >
                    {!hiddenState ? (
                      <VisibilityOffIcon />
                    ) : (
                      <RemoveRedEyeIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Tooltip title={textCopied ? "Copied" : "Copy value"}>
              <IconButton
                size="small"
                color="primary"
                onClick={(_) => {
                  navigator.clipboard.writeText(v.value);
                  setTextCopied(true);
                  setTimeout(() => {
                    setTextCopied(false);
                  }, 5000);
                }}
                sx={{
                  zIndex: 10,
                  width: "10%",
                }}
              >
                <CopyAllIcon />
              </IconButton>
            </Tooltip>
          </FormControl>
          {isSm && (
            <Box display="flex" gap={1} alignItems="center">
              <Typography className="key">{v.key}</Typography>
              <Tooltip title={textCopied ? "Copied" : "Copy key value"}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(_) => {
                    navigator.clipboard.writeText(v.key + " " + v.value);
                    setTextCopied(true);
                    setTimeout(() => {
                      setTextCopied(false);
                    }, 5000);
                  }}
                  sx={{
                    zIndex: 10,
                  }}
                >
                  <CopyAllIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Grid>
      </React.Fragment >
    );
  };
  console.log(project);
  console.log(user);
  console.log(myTeam);

  return (
    <Container sx={{ alignSelf: "flex-start" }}>
      {project.length > 0 && (
        <FileModal
          project={project[0]}
          user={user}
          openFile={openFile}
          setFileOpen={setFileOpen}
        />
      )}
      <MuiGrid container spacing={1}>
        <Grid item xs={12} sm={12} md={9} width="100%">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: { xs: "center", sm: "flex-start" },
              mb: { xs: 3, md: 4 },
            }}
          >
            <Typography
              sx={{ color: "#000", fontWeight: 700, fontSize: 24, flex: 1 }}
            >
              {error ? error : project[0]?.project_name}
            </Typography>
            <IconButton
              color="primary"
              onClick={() => navigate(-1)}
              sx={{ bgcolor: "rgba(74, 58, 255, 0.04)" }}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={9} width="100%">
          <ProjectDetailCard loading={loading} isSm={isSm} error={error}>
            {!loading ? (
              <>
                {error ? (
                  <Typography
                    sx={{ flexGrow: { xs: 0, sm: 1, textAlign: "center" } }}
                    className={"heading error"}
                  >
                    {error ? error : project[0]?.project_name}
                  </Typography>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <Typography variant='h5' sx={{ flexGrow: { xs: 0, sm: 1 } }} className={"heading"}>{error ? error : project[0]?.project_name}</Typography> */}
                    <Box
                      sx={{
                        position: "relative",
                        display: "flex",
                        alignSelf: "flex-start",
                      }}
                    >
                      {myTeam
                        ?.filter((_v, i) => i < 6)
                        ?.map((v, i) => (
                          <CustomTooltip
                            title={
                              v?.displayName ||
                              v?.email
                                ?.split("@")[0]
                                ?.charAt(0)
                                ?.toUpperCase() +
                              v?.email?.split("@")[0]?.slice(1)
                            }
                          >
                            <Avatar
                              key={v?.id || i}
                              src={v?.photoURL || " "}
                              alt={
                                v?.displayName?.toUpperCase() ||
                                v?.email?.toUpperCase()
                              }
                              sx={{
                                border: "1px solid #FFFFFF",
                                position: "absolute",
                                left: (i + 1) * 17,
                              }}
                            />
                          </CustomTooltip>
                        ))}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body1" color="initial">
                        <IconButton
                          aria-label=""
                          LinkComponent={Link}
                          // to={`/update-project/${project?.[0]?.id}`}
                          onClick={() =>
                            navigate(`/update-project/${project?.[0]?.id}`, {
                              state: project?.[0],
                            })
                          }
                        >
                          {/* <EditIcon /> */}
                          {user?.uid === project?.[0]?.uid ||
                            myTeam?.filter((v) => v?.uid === user?.uid)[0]
                              ?.teamManager === true ? (
                            <EditIcon />
                          ) : null}
                        </IconButton>
                      </Typography>
                      <Typography variant="body1" color="initial">
                        Files :{" "}
                        {project[0]?.files ? project[0]?.files?.length : "0"}
                      </Typography>
                      <IconButton
                        aria-label=""
                        onClick={() => setFileOpen(true)}
                      >
                        <FileCopyOutlinedIcon
                          sx={{
                            color:
                              project[0]?.files &&
                              project[0]?.files?.length > 0 &&
                              "#00AE96",
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                )}
                {project.length > 0 && !error && (
                  <ProjectContent>
                    <Box
                      sx={{
                        width: "33.33%",
                        height: "100%",
                        position: "absolute",
                        background: "#4a3aff0a",
                        zIndex: 1,
                      }}
                    />
                    <Box
                      sx={{
                        width: "66.66%",
                        height: "100%",
                        right: "0%",
                        position: "absolute",
                        zIndex: 3,
                      }}
                    />
                    <Grid container>
                      <Grid item xs={4} className="left">
                        <Typography>Project Name:</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        className="right"
                        sx={{ paddingTop: "14px" }}
                      >
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {project[0]?.project_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className="left">
                        <Typography>Project Type:</Typography>
                      </Grid>
                      <Grid item xs={8} className="right">
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {project[0]?.project_type}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className="left info">
                        <Typography>Project Information:</Typography>
                      </Grid>
                      <Grid item xs={8} className="right info">
                        <Typography>{project[0]?.project_info}</Typography>
                      </Grid>
                      {project[0]?.extraInfo?.map((v, i) => (
                        <KeyValueComponent v={v} i={i} key={i} />
                      ))}
                    </Grid>
                  </ProjectContent>
                )}
              </>
            ) : (
              <CircularProgress
                color="primary"
                style={{ width: "40px", height: "40px" }}
              />
            )}
          </ProjectDetailCard>
        </Grid>
      </MuiGrid>
    </Container>
  );
};

export default ProjectDetails;
