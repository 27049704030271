import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "2px",
    boxShadow: 24,
    py: 2,
    px: 3,
    outline: "none",
    "& div": {
        display: "flex",
        justifyContent: "space-around",
        "& button": {
            mt: "1rem",
            width: 100
        }
    }
};

function ConfirmModal({ openDelete, handleDeleteClose, onClick, title }) {

    const handleYes = () => {
        onClick()
        handleDeleteClose()
    }

    return (
        <Modal
            open={openDelete}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" textAlign="center">
                    {title || "Are you sure? this will delete it permanently!"}
                </Typography>
                <Box>
                    <Button variant="outlined" onClick={handleDeleteClose}>No</Button>
                    <Button variant="contained" onClick={handleYes}>Yes</Button>
                </Box>
            </Box>
        </Modal>
    )
}

export default ConfirmModal