import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Avatar, Box, ListItemAvatar, Typography } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function MultipleSelectCheckmarks({ placeholder, teams, setAssignedTeam, assignedTeam, project_type }) {

    const handleChange = (event) => {
        const {
            target: { value }
        } = event;
        setAssignedTeam(value)
    };

    return (
        <FormControl
            fullWidth
            disabled={project_type === "solo"}
            sx={{ my: 1 }}
        >
            <InputLabel id="demo-multiple-checkbox-label" sx={{ top: -5, fontWeight: "normal !important", }}>{placeholder}</InputLabel>
            <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                value={assignedTeam}
                onChange={handleChange}
                input={<OutlinedInput label={placeholder} />}
                disabled={project_type === "solo"}
                renderValue={() => {
                    return assignedTeam.map(memberUid => {
                        let findObj = teams.find(user => user.uid === memberUid)
                        if (findObj) return findObj.displayName || findObj.email.split('@')[0]
                    }).join(", ")
                }}

                MenuProps={MenuProps}
                sx={{
                    "& .MuiSelect-select.MuiSelect-outlined.MuiSelect-multiple": {
                        padding: "11px 14px!important",
                        fontSize: "14px",
                    },
                    "& svg": {
                        display: "none"
                    }
                }}
            >
                {teams.map((team, idx) => (
                    <MenuItem key={idx} value={team.uid} sx={{ px: 0.8, pl: 0 }}>
                        <Checkbox checked={assignedTeam.includes(team.uid)} />
                        <ListItemAvatar sx={{ minWidth: "50px" }}>
                            <Avatar
                                src={team.photoURL}
                                alt={team.displayName?.toUpperCase() || team.email?.toUpperCase()}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={team.displayName || team.email.split('@')[0]}
                            secondary={team.position || "Position (not available)"}
                            primaryTypographyProps={{
                                variant: 'body2',
                                sx: {
                                    color: "#000",
                                    fontWeight: 500,
                                    whiteSpace: 'break-spaces',
                                    overflowWrap: 'anywhere',
                                    lineHeight: "1.3rem"
                                }
                            }}
                            secondaryTypographyProps={{
                                variant: 'caption1',
                                sx: {
                                    whiteSpace: 'break-spaces'
                                }
                            }}
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
