import { createContext, useReducer, useEffect } from 'react'
import { db } from '../firebase/config'
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { useAuthContext } from '../hooks/useAuthContext'

export const MyTeamContext = createContext()

export const myTeamReducer = (state, action) => {
    switch (action.type) {
        case 'setMyTeam':
            return { ...state, myTeam: action.payload, isTeamLoading: false, error: null }
        case 'removeMyTeam':
            return { ...state, myTeam: null, isTeamLoading: false, error: null }
        case 'setError':
            return { ...state, error: action.payload, isTeamLoading: false }
        case 'removeError':
            return { ...state, error: null, isTeamLoading: false }
        case 'setIsFetched':
            return { ...state, isFetched: true }
        case 'removeIsFetched':
            return { ...state, isFetched: false }
        default:
            return state
    }
}

export const MyTeamContextProvider = ({ children }) => {
    const { user } = useAuthContext()

    const [state, dispatch] = useReducer(myTeamReducer, {
        myTeam: null,
        isTeamLoading: true,
        isFetched: false,
        error: null
    })

    const getMyTeam = async () => {
        const unsub = onSnapshot(collection(db, "users", user.uid, 'team'), async myTeamSnap => {
            if (myTeamSnap.docs.length > 0) {
                const membersArr = []
                const teamUsers = []
                myTeamSnap.docs.forEach(async member => {
                    membersArr.push({ ...member.data() })
                    const queryOnMember = query(collection(db, "users"), where("email", "==", member.data().email))
                    teamUsers.push(getDocs(queryOnMember))
                });
                let getTeamUsers = await Promise.all(
                    teamUsers.map(async queryUserSnap => {
                        let getUser = await queryUserSnap
                        if (queryUserSnap.empty) return false;
                        getUser = getUser.docs.map(doc => ({ ...doc.data(), uid: doc.id }))[0]
                        return getUser
                    })
                )
                getTeamUsers = membersArr.map(member => {
                    return ({
                        ...member,
                        ...(getTeamUsers.find(owner => owner?.email === member.email) || {})
                    })
                })
                dispatch({ type: 'setMyTeam', payload: getTeamUsers })
                dispatch({ type: 'setIsFetched' })
            } else {
                dispatch({ type: "removeMyTeam" })
            }
            dispatch({ type: "removeError" })
        }, error => {
            dispatch({ type: "setError", payload: error.message })
        })
        return () => unsub()
    }

    useEffect(() => {
        if (user && !state.isFetched) {
            getMyTeam()
        }
        else {
            dispatch({ type: "removeError" })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, state.isFetched])

    return (
        <MyTeamContext.Provider value={{ ...state, dispatch }}>
            {children}
        </MyTeamContext.Provider>
    )
}
