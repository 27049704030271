import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NewPricingCard from "../landing/NewPricingCard";
import {
  Container,
  DialogActions,
  DialogContent,
  Slide,
  Stack,
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Tooltip,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useTeamOwnersContext } from "../../hooks/useTeamOwnersContext";

const StyledBox = styled(Box)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "& .team": {
      display: "flex",
      // alignItems: "center",
      flexDirection: "column",
      border: "1px solid #8080802e",
      padding: theme.spacing(1.2),
      borderLeft: "2px solid #4A3AFF",
      position: "relative",
      gap: "10px",
      backgroundColor: "#FFFFFF",
      boxShadow: "rgb(0 0 0 / 5%) 0px 0px 5px 1px",
      // "& .MuiTypography-root": {
      //     textAlign: "center",
      // },
      "& .first": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        "& > .avatar": {
          position: "relative",
          width: "fit-content",
          borderRadius: "50%",
          "& > .MuiAvatar-root": {
            width: 60,
            height: 60,
            [theme.breakpoints.down("sm")]: {
              width: 50,
              height: 50,
            },
          },
        },
        "& > .txt": {
          flex: 1,
          paddingRight: "8px",
          "& > .MuiTypography-root": {
            overflowWrap: "anywhere",
            "&.name": {
              fontWeight: 700,
              color: "#000",
              textTransform: "capitalize",
              maxWidth: "86.5%",
              lineHeight: "22px",
            },
          },
        },
      },
    },
  },
  "& .MuiDialogActions-root": {
    "& button": {
      minWidth: "80px !important",
    },
  },
}));

function UpgradePage({ userRole }) {
  const { teamOwners, dispatch } = useTeamOwnersContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (userRole === "team") {
      navigate("/projects", { replace: true });
    }
  }, [userRole]);

  const convertSinceDate = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const getDate = new Date(date);
    return `${monthNames[getDate.getMonth()]} ${getDate.getFullYear()}`;
  };
  return (
    <>
      <Box
        className="main-parent"
        sx={{
          display: "flex",
          width: { xs: "95%", md: "100%" },
          margin: { xs: "0 auto", md: "0" },
          justifyContent: "space-evenly",
          flexDirection: { md: "row", xs: "column" },
          alignItems: "start",
        }}
      >
        <Box
          className="team-parent"
          sx={{
            display: "flex",
            width: { md: "40%", xs: "100%" },
            justifyContent: "flex-start",
            flexDirection: "column",
            padding: "1rem",
            backgroundColor: "white",
            marginTop: "35px",
          }}
        >
          <StyledBox>
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                // px: { xs: 2 },
                paddingTop: "0",
                paddingLeft: "0",
                fontSize: "1.5rem",
                color: "#505962",
              }}
            >
              Other Teams You're in
            </DialogTitle>
            <DialogContent
              sx={{
                overflow: "hidden",
                // px: { xs: 2 },
                minHeight: "100px",
                maxHeight: "500px",
                overflowY: "auto",
                paddingLeft: "0px",
              }}
            >
              {teamOwners?.length ? (
                teamOwners.map(({ owner, position, sinceDate }, idx) => (
                  <Box className="team" key={owner.email}>
                    <Box className="first">
                      <Box className="avatar">
                        <Avatar
                          src={owner?.photoURL || " "}
                          alt={
                            owner?.displayName?.toUpperCase() ||
                            owner?.email?.toUpperCase()
                          }
                        />
                      </Box>
                      <Box className="txt">
                        <Typography className="name" variant="body1">
                          {(owner.managerInfo && owner.managerInfo.teamName) ||
                            `team ${owner?.displayName?.length > 5
                              ? owner.displayName.split(" ")[0]
                              : owner?.displayName || "NA"
                            }`}
                        </Typography>
                        <Typography variant="body2" pr={2}>
                          {owner.email}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className="second"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0px",
                        "& >.MuiBox-root": {
                          display: "flex",
                          gap: "10px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        },
                        "& > .MuiBox-root > .MuiBox-root": {
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        },
                      }}
                    >
                      <Box>
                        <Box>
                          <Typography
                            variant="body2"
                            fontWeight={500}
                            color="#505962"
                          >
                            Owner:
                          </Typography>
                          <Typography variant="body2">
                            {owner?.displayName}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            fontWeight={500}
                            color="#505962"
                          >
                            Position:
                          </Typography>
                          <Typography variant="body2">
                            {position || "NA"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box>
                          <Typography
                            variant="body2"
                            fontWeight={500}
                            color="#505962"
                          >
                            Total Members:
                          </Typography>
                          <Typography variant="body2">
                            {owner?.team?.length}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="body2"
                            fontWeight={500}
                            color="#505962"
                          >
                            Since:
                          </Typography>
                          <Typography variant="body2">
                            {convertSinceDate(sinceDate) || "NA"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography variant="body1" className="nit">
                  You are not in anyone's team yet
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Stack columnGap={0.6} flexDirection="row" alignItems="center">
                <Typography variant="body1" fontWeight={700} color="#505962">
                  No of Teams:
                </Typography>
                <Typography variant="body1">
                  {teamOwners?.length || "0"}
                </Typography>
              </Stack>
            </DialogActions>
          </StyledBox>
        </Box>
        <Box
          className="my-card"
          sx={{
            // display: "flex",
            width: { md: "40%", xs: "100%" },
            // justifyContent: "space-evenly",
            // flexDirection: "column",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "black",
              textAlign: "center",
              marginTop: { xs: "5px", md: "0" },
            }}
          >
            Create Your Own Team
          </Typography>
          <Grid container-fluid spacing={3} justifyContent="center">
            <NewPricingCard cardsToShow={1} userRole={userRole} />
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default UpgradePage;
