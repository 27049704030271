import React from 'react'
import { styled } from "@mui/material/styles"
import { CircularProgress, Container } from "@mui/material"


const MuiContainer = styled(Container)(() => ({
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0

}));

const CircularLoader = () => {
    return (
        <MuiContainer>
            <CircularProgress style={{ width: "40px", height: "40px" }} />
        </MuiContainer>
    )
}

export default CircularLoader