import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: 'AIzaSyC823DffHeZVDncaVEYaeinpsFFAhJkUHw',
  authDomain: 'cred-management-54b0e.firebaseapp.com',
  projectId: 'cred-management-54b0e',
  storageBucket: 'cred-management-54b0e.appspot.com',
  messagingSenderId: '317217357033',
  appId: '1:317217357033:web:633f104f65b5214d0f4478',
  measurementId: 'G-42WD11LZ39',
}

const app = initializeApp(firebaseConfig)

const db = getFirestore()
const auth = getAuth()
const bucket = getStorage()
const analytics = getAnalytics(app)
const provider = new GoogleAuthProvider();

export { db, auth, bucket, provider }
