import { Menu, MenuItem, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function BasicMenu({ anchorEl, open, handleClose, options, setAnchorEl, iconProps = {} }) {
  // console.log(options);
  const navigate = useNavigate();

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {options
        ?.filter((opt) => (opt.hasOwnProperty("show") ? opt.show : true))
        .map((v, i) => (
          <MenuItem
            key={i}
            disabled={v?.disabledInvite}
            state={v?.state}
            sx={{ pl: 1, pr: 4, display: "flex", ...v?.sx }}
            onClick={() => {
              setAnchorEl(null);
              if (v?.linkTo) {
                return navigate(v.linkTo, { state: v.state });
              }
              return v.onClick();
            }}
          >
            <IconButton {...iconProps}>{v.icon}</IconButton>
            <Typography ml={0.8}>{v.name}</Typography>
          </MenuItem>
        ))}
    </Menu>
  );
}

export default BasicMenu;
