import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useCollection } from '../../hooks/useCollection';
import { format } from 'date-fns';
import { styled } from "@mui/material/styles"
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useNavigate } from 'react-router-dom';


const StyledMenuItem = styled(MenuItem, {
    shouldForwardProp: prop => prop !== "seen"
})(({ theme, seen }) => ({
    display: "flex",
    flexDirection: "column",
    // gap: "10px",
    padding: 0,
    "& > .MuiBox-root": {
        display: "flex",
        alignItems: "flex-start",
        backgroundColor: !seen && (theme.palette.primary.main + "1f"),
        gap: "0.8rem",
        width: "100%",
        padding: "15px 10px",
        "& .MuiAvatar-root": {
            // marginLeft: "4px",
        },
        "& .content": {
            overflow: "hidden",
            flex: 1,
            width: "100%",
        }
    },
    "&:last-f-type .MuiDivider-root": {
        display: "none",
    }
}))


export default function AccountMenu({ anchorEl, setAnchorEl, receivedNoti, openNoti, uid }) {

    const navigate = useNavigate()

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [notiWithUsers, setNotiWithUsers] = React.useState([])


    React.useEffect(() => {
        if (openNoti && receivedNoti) {
            const unseen = receivedNoti?.filter(v => !v.seen)
            unseen?.forEach((v) => {
                const ref = doc(db, "notifications", v.id)
                setTimeout(async () => {
                    await updateDoc(ref, { seen: true })
                }, 3500)
            })
        }
    }, [receivedNoti, openNoti])

    React.useEffect(() => {
        (async _ => {
            if (!receivedNoti && receivedNoti?.length === 0) return false;
            let getNotiUsers = await Promise.all(
                receivedNoti.map(async noti => {
                    let getUser = await getDoc(doc(db, "users", noti?.sender_id))
                    getUser = { ...getUser.data(), uid: getUser.id }
                    return { sender: getUser, ...noti }
                })
            )
            setNotiWithUsers(getNotiUsers)
        })()
    }, [receivedNoti])

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openNoti}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    py: 0,
                    maxWidth: { xs: "300px", sm: "400px", md: "480px" },
                    maxHeight: "500px",
                    overflow: "scroll",
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    "& > ul": {
                        py: 0,
                    },
                    '& .MuiAvatar-root': {
                        width: { xs: 38, sm: 48 },
                        height: { xs: 38, sm: 48 },
                        // ml: -0.5,
                        mr: 0,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}

            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {notiWithUsers.length > 0 ? notiWithUsers?.map((noti, i) => {
                let sender = noti.sender || {};
                return (
                    <StyledMenuItem seen={noti?.seen} key={i} onClick={() => noti.link && navigate(noti.link)}>
                        <Box>
                            <Avatar src={(sender && sender?.photoURL) || (sender?.email?.toUpperCase() || "")} alt={sender?.displayName || sender?.email?.split("@")[0]?.toUpperCase()} sx={{ mr: 2 }} />
                            <Box sx={{}} className="content">
                                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, flexWrap: "wrap", "&>p": { color: "black" } }}>
                                    <Typography variant='body2' fontWeight="700">{noti?.title}</Typography>
                                    {!(noti?.sender_id === uid) && <>
                                        <Typography variant='body2' fontWeight={"bold"} sx={{ color: "#6F6C90 !important" }} >by "{sender ? (sender?.displayName || sender?.email?.split("@")[0]?.substring(0, 8)) : " "}"</Typography>
                                    </>}

                                </Box>
                                <Box variant='caption1' sx={{ maxWidth: "80vw", display: "flex", flexWrap: "wrap", whiteSpace: "break-spaces", gap: 0.5 }}>
                                    <div dangerouslySetInnerHTML={{ __html: noti?.message }} style={{ fontSize: "15px" }}></div>
                                </Box>
                                <Box>
                                    <Typography variant='caption2' fontWeight="500"> at {" "}
                                        {(format(new Date(noti?.createdAt && noti?.createdAt?.toDate()), "yyyy-MM-dd") ===
                                            format(new Date(), "yyyy-MM-dd")) ?
                                            format(noti?.createdAt?.toDate(), "PPP ,p") :
                                            noti?.createdAt?.toDate()?.toDateString()
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Divider variant='fullWidth' width="100%" />
                    </StyledMenuItem>
                )
            }) : <MenuItem onClick={handleClose}>No Notification!</MenuItem>

            }
        </Menu>
    );
}
