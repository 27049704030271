import { useCallback, useEffect, useState } from 'react'
import { auth, bucket } from '../../../firebase/config'
import { updateProfile } from 'firebase/auth'
import { useAuthContext } from '../../../hooks/useAuthContext'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  ref,
  // snapshot,
  // uploadBtyes,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage'


import { styled } from '@mui/material/styles'
import { Box, Typography, Container, Grid, Avatar, TextField, Button, LinearProgress, FormControl, FormLabel, FormHelperText } from '@mui/material'
import { useDropzone } from 'react-dropzone';
import { useUpdate } from '../../../hooks/useUpdate';
import { useCollection } from '../../../hooks/useCollection';
import { useForm } from 'react-hook-form';


const ProfileInfoCard = styled(Box, {
  shouldForwardProp: prop => prop !== "cardWrapped"
})(({ theme, cardWrapped }) => ({
  background: theme.palette.secondary.light,
  // boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
  boxShadow: "0px 3px 11px rgba(0, 0, 0, 0.06), 0px 10px 15px rgba(0, 0, 0, 0.03)",
  borderRadius: cardWrapped ? "0 0 3px 3px" : "0 3px 3px 0",
  padding: "25px 15px",
  border: "1px solid #80808040",
  minWidth: "400px",
  ['@media (max-width:1100px)']: {
    minWidth: "300px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 10px",
  },
  "& > form": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    // border: "1px solid",
    height: "100%",
    padding: "0px 10px",
    "&>*": {
      marginBottom: "20px",
    },
    "&>.MuiTypography-root": {
      textTransform: "capitalize",
      // marginBottom: "5%",
      // padding: "5px 0px",
      fontWeight: "700",
      letterSpacing: "1px",
      // fontSize: "1.2rem",
    },
    "& .MuiFormControl-root": {
      "& .MuiFormLabel-root": {
        color: "#000",
        fontWeight: 500,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 2,
        // "& input": {
        //   padding: "8px 14px",
        // },
        "& input#select-file": {
          // backgroundColor: "red"
          // display: "none"
        },
      },
      "& > p.MuiFormHelperText-root": {
        color: "red",
        marginLeft: "2px",
      },
    },
  },

}))
const ProgressBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "5px",
  width: "-webkit-fill-available",
  "&>.MuiTypography-root": {
    fontSize: "10px",
    fontWeight: "bold",
    letterSpacing: "0.5px",
  },
  "&>.MuiLinearProgress-root": {
    width: "100%",
    height: "6px",
    borderRadius: 3,
    backgroundColor: "#D9D9D9",
    "&>.MuiLinearProgress-bar": {
      backgroundColor: "rgb(0 0 0 / 70%)",
    },
  },
  "&>button": {
    textTransform: "capitalize",
    borderRadius: 1,
    minWidth: "45px",
    color: "#fff",
    fontSize: "8px",
    padding: "2px",
    letterSpacing: "0.5px",
    background: "rgb(0 0 0 / 40%)",
    "&:hover": {
      background: "rgb(0 0 0 / 50%)",
    }
  },

}))

const Profile = ({ refrence, cardWrapped, thumbnailUrl, setThumbnailUrl, user, displayName, setDisplayName }) => {

  const { documents: currentUser } = useCollection("users", [
    'email',
    '==',
    user.email,
  ])

  const {
    register,
    handleSubmit,
    reset,
    setError,
    control,
    clearErrors,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      displayName: displayName,
      phone: "",
    },
  })

  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailError, setThumbnailError] = useState(null)
  const { updateSetting, error, loading } = useUpdate()

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (!!currentUser && currentUser?.length > 0) {
      let { displayName, phone } = currentUser[0]
      reset({ displayName, phone })
    }
  }, [currentUser])

  const onSubmit = async (data) => {
    setProgress(0)
    setThumbnailError(null)

    let payload = {
      ...data,
      photoURL: thumbnailUrl
    }

    if (data.displayName && thumbnailUrl) {
      payload.displayName = data.displayName
      payload.photoURL = thumbnailUrl
    }
    else if (thumbnailUrl) {
      payload.photoURL = thumbnailUrl
    }
    else if (data.displayName) {
      payload.displayName = displayName
    }

    await updateSetting(user, "users", payload)
  }

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setThumbnail(null)

    let selected = acceptedFiles[0]

    if (!selected) {
      setThumbnailError('select image file')
      return
    }

    if (!selected.type.includes('image')) {
      setThumbnailError('must be an image file')
      return
    }

    if (selected.size > 2500000) {
      setThumbnailError('image size must be < 2.5 MB')
      return
    }
    setThumbnailError(null)
    const splitting = selected.name.split(".")
    // const profileType = splitting[1]

    const storageRef = ref(bucket, `/profilePic/${user.uid}`);
    const uploadTask = uploadBytesResumable(storageRef, selected);
    uploadTask.on('state_changed',
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        )
        setProgress(prog)
      },
      (err) => {
        console.log(err);
      },
      async () => {
        const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)
        setThumbnailUrl(downloadUrl)
      }
    )

    setThumbnailError(null)
    setThumbnail(selected)
  }, [setThumbnailUrl])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })


  return (
    <ProfileInfoCard cardWrapped={cardWrapped} ref={refrence}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className='inputs'>
          <Grid item xs={12} md={6} >
            <FormControl fullWidth>
              <FormLabel sx={{ pb: 1 }}>Fullname</FormLabel>
              <TextField
                variant="outlined"
                placeholder="Fullname"
                sx={{ borderRadius: 0, bgcolor: "#FFFFFF" }}
                fullWidth
                size="small"
                error={!!errors.displayName}
                {...register("displayName", { required: true })}
              />
              <FormHelperText>
                {errors.displayName && "This is required"}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} >
            <FormControl fullWidth>
              <FormLabel sx={{ pb: 1 }}>Phone</FormLabel>
              <TextField
                variant="outlined"
                placeholder="Phone"
                sx={{ borderRadius: 0, bgcolor: "#FFFFFF" }}
                fullWidth
                size="small"
                error={!!errors.phone}
                {...register("phone", { required: false, pattern: { value: /^[0-9\b]+$/, message: "Plz Enter phone no" } })}
              />
              <FormHelperText>
                {errors.phone && "This is required"}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Box {...getRootProps()} sx={{ borderRadius: 0.6, padding: "1.5rem", minHeight: "100px", border: "1px dashed black", display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center", gap: "8px", width: "-webkit-fill-available" }}>
          <input type='file'  {...getInputProps()} />
          {/* <span>profile image</span> */}
          {thumbnailError && <div className='error'>{thumbnailError}</div>}
          {error && <div className='error'>{error}</div>}
          <UploadFileIcon sx={{ fontSize: "20px", width: "1.5em", height: "1.5em", color: "rgb(0,0,0,70%)" }} />
          <Typography sx={{ fontSize: "17px", textAlign: "center" }}>Select a image file to upload</Typography>
          <Typography sx={{ fontSize: "14px", textAlign: "center", color: "rgb(0,0,0,70%)" }}>Or drag and drop it here</Typography>
        </Box>
        <ProgressBox>
          <Typography>{progress}%</Typography>
          <LinearProgress variant="determinate" color="primary" value={progress} />
          <Button variant="contained" type="button" disabled={progress == 0 || progress === 100}>
            cancel
          </Button>
        </ProgressBox>
        <Button
          color="primary"
          variant="contained"
          type={"submit"}
          disabled={loading}
          sx={{ textTransform: "capitalize", padding: "5px 15px", marginLeft: "auto", marginRight: "auto" }}
        >
          update profile
        </Button>
      </form>
    </ProfileInfoCard>
  )
}

export default Profile
