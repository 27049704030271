import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { auth, db, provider } from "../firebase/config";
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { useRegister } from "./useRegister";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const { dispatch } = useAuthContext();
  const { addUser } = useRegister();

  const login = async (email, password, successFunc, logout = () => null) => {
    setError(null);
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      // dispatch({ type: 'LOGIN', payload: res.user })
      dispatch({ type: "AUTH_IS_READY", payload: res.user });
      const docRef = doc(db, "users", res.user.uid);
      const userSnap = await getDoc(docRef);
      dispatch({
        type: "AUTH_IS_READY",
        payload: { ...res.user, ...userSnap.data(), uid: userSnap.id },
      });
      successFunc();
    } catch (error) {
      setError(error);
      setTimeout(() => {
        setError("");
      }, 4500);
    }
  };

  const handleGoogleSignIn = (successFunc) =>
    signInWithPopup(auth, provider)
      .then(async (result) => {
        setLoading(true);
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const docRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(docRef);
        !userSnap.exists() && (await addUser(result.user));

        dispatch({
          type: "AUTH_IS_READY",
          payload: { ...user, ...userSnap.data(), uid: userSnap.id },
        });

        successFunc();
        // ...
        setLoading(false);
      })
      .catch((error) => {
        // Handle Errors here.
        // const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        setError(error);
        console.log(errorMessage);
        setTimeout(() => {
          setError("");
        }, 4500);
        setLoading(false);
      });

  return { error, loading, login, setError, handleGoogleSignIn };
};
