import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { doc, getDocs, updateDoc } from "firebase/firestore";

import { useLogout } from "../../hooks/useLogout";
import {
  Button,
  Box,
  IconButton,
  useMediaQuery,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  Link as MuiLink,
  Tooltip,
  Avatar,
  Stack,
  Badge,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "../../images/CM_Logo_PNG1.png";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/NotificationsNoneOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import HomeIcon from "@mui/icons-material/Home";
import AboutIcon from "@mui/icons-material/Group";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import ArticleIcon from "@mui/icons-material/Article";
import ReviewsIcon from "@mui/icons-material/Reviews";
import SettingsIcon from "@mui/icons-material/Settings";
import UserMenu from "./UserMenu";
import AccountMenu from "../Notifications/notifications";
import { db } from "../../firebase/config";
import {
  collection,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0.85rem 3rem",
  background: "#FFFFFF",
  boxShadow: "0px 4px 28px rgba(2, 2, 2, 0.1)",
  zIndex: 10,
  gap: "10px",

  [theme.breakpoints.down("md")]: {
    paddingLeft: "0.85rem 1rem",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },

  "& > .logo": {
    width: 70,
    height: 48,
    position: "relative",
    "& img": {
      position: "absolute",
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: 55,
      height: 38,
    },
  },
  "& > .middleRoutes": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    marginLeft: "100px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "10px",
      gap: "5px",
    },
    "& > a.MuiTypography-root": {
      fontSize: "16px",
      padding: "2px 5px",
      cursor: "pointer",
      textDecoration: "none",
      color: "#170F49",
      transition: "all 0.4s ease",
      textAlign: "center",
      "&.active": {
        color: theme.palette.primary.main,
      },
      "&:hover": {
        color: "#4a3affba",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        padding: "2px",
      },
    },
  },
  "& ul": {
    display: "flex",
    alignItems: "center",
    gap: "1rem",

    "& > li": {
      "& > a, & > button": {
        letterSpacing: "1px",
        minHeight: "44px",
        minWidth: "120px",
        textTransform: "capitalize",
        cursor: "pointer",
        border: `1.4px solid ${theme.palette.primary.main} !important`,
        fontWeight: "700",
        "& > svg": {
          color: theme.palette.primary.main,
        },
        [theme.breakpoints.down("sm")]: {
          minHeight: "30px",
          minWidth: "80px",
          fontSize: "12px",
        },
      },
    },
  },
}));

const Navbar = ({ userRole, setUserRole }) => {
  const {
    user: currentUser,
    authIsReady,
    loading,
    dispatch,
  } = useAuthContext();
  const { logout } = useLogout();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElNoti, setAnchorElNoti] = useState(null);
  const handleClick = (event) => {
    setAnchorElNoti(event.currentTarget);
  };

  const openNoti = Boolean(anchorElNoti);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [receivedNoti, setReceivedNoti] = useState([]);

  const handleSales = async (result) => {
    let myHeaders = new Headers();
    myHeaders.append("x-api-key", process.env.REACT_APP_FIRSTPROMOTER_API_KEY);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    let urlencoded = new URLSearchParams();
    urlencoded.append("email", currentUser.email);
    // urlencoded.append("uid", currentUser.uid);
    urlencoded.append("currency", "USD");
    urlencoded.append("event_id", currentUser?.stripeId);
    urlencoded.append("plan", userRole);
    urlencoded.append("amount", result?.[0]?.items?.[0]?.plan.amount);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };

    const response = await fetch(
      "https://firstpromoter.com/api/v1/track/sale",
      requestOptions
    );
    const salesResult = await response.text();
    if (salesResult) {
      await handleIsSaled();
    }
  };

  const handleIsSaled = async () => {
    const docRef = doc(db, "users", currentUser.uid);
    await updateDoc(docRef, {
      isSaled: true,
    });
    // setUser({ ...user, isSaled: true })
    dispatch({
      type: "AUTH_IS_READY",
      payload: { ...currentUser, isSaled: true },
    });
  };

  useEffect(() => {
    if (currentUser) {
      (async () => {
        try {
          const userPaymentRef = collection(
            db,
            "users",
            currentUser.uid,
            "subscriptions"
          );
          const snapUserSubs = await getDocs(userPaymentRef);
          const result = await Promise.all(
            snapUserSubs.docs.map(async (doc) => {
              const obj = { ...doc.data(), docId: doc.id };
              return obj;
            })
          );
          if (
            result?.[0]?.status === "active" &&
            userRole === "team" &&
            !currentUser?.isSaled
          ) {
            await handleSales(result);

            // !currentUser?.referral && (await handleIsSaled())
            console.log("success");
          }
        } catch (err) {
          console.log(err.message);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (db && currentUser) {
      const msgRef = collection(db, "notifications");
      const receiverQ = query(
        msgRef,
        where("receiver_id", "==", currentUser?.uid),
        limit(200)
      );
      const unsubcribe = onSnapshot(receiverQ, (snapshot) => {
        let results = [];
        snapshot.docs.forEach((doc) => {
          results.push({ ...doc.data(), id: doc.id });
        });
        setReceivedNoti(
          results.sort((a, b) => {
            const aDate = new Date(a?.createdAt?.toDate());
            const bDate = new Date(b?.createdAt?.toDate());

            return bDate.getTime() - aDate.getTime();
          })
        );
      });
      return () => unsubcribe();
    }
  }, [currentUser]);

  const isMobile = useMediaQuery(`(max-width:${900}px)`);
  const showLogins = useMediaQuery(`(min-width:${490}px)`);

  const LoginRoutes = ({ className = "" }) => (
    <ul className={className}>
      <li>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to="/login"
          className={location.pathname === "/login" ? "active" : ""}
        >
          Login
        </Button>
      </li>
      <li>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/register"
          className={location.pathname === "/register" ? "active" : ""}
        >
          Register
        </Button>
      </li>
    </ul>
  );

  const middleRoutes = [
    {
      title: "Home",
      to: "/",
      authenticated: false,
      icon: <HomeIcon fontSize="small" />,
      show: "team",
    },
    {
      title: "About",
      to: "#about",
      authenticated: false,
      icon: <AboutIcon fontSize="small" />,
      show: "team",
    },
    {
      title: "Pricing",
      to: "#pricing",
      authenticated: false,
      icon: <MonetizationOnIcon fontSize="small" />,
      show: "team",
    },
    // {
    //   title: "Contact",
    //   to: "#contact",
    //   authenticated: false,
    //   icon: <ContactPhoneIcon fontSize="small" />,
    //   show: "team",
    // },
    {
      title: "Testimonials",
      to: "#testimonials",
      authenticated: false,
      icon: <ReviewsIcon fontSize="small" />,
      show: "team",
    },
    // {
    //   title: "Dashboard",
    //   to: "/dashboard",
    //   authenticated: true,
    //   icon: <DashboardIcon fontSize="small" />,
    //   show: "team",
    // },
    {
      title: "Projects",
      to: "/projects",
      authenticated: true,
      icon: <ArticleIcon fontSize="small" />,
      show: "team",
    },
    {
      title: "Team",
      to: `/myteam/${currentUser?.uid}`,
      authenticated: true,
      icon: <GroupsIcon fontSize="small" />,
      show: userRole,
    },
    // {
    //   title: "Settings",
    //   to: "/settings",
    //   authenticated: true,
    //   icon: <SettingsIcon fontSize="small" />,
    //   show: "team",
    // },
    {
      title: "Affiliate",
      to: "/affiliate",
      authenticated: true,
      icon: <ArticleIcon fontSize="small" />,
      show: "team",
    },
  ];

  const handleLogout = async () => {
    await logout();
    setUserRole(undefined);
    navigate(location.state?.from?.pathname || "/login", { replace: true });
  };

  const scrollToElement = (id) => {
    const section = document.querySelector(id);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    if (location.state === "#pricingcard") {
      scrollToElement(location.state);
    }
  }, [location]);

  return (
    <Box sx={{ display: "flex" }} id="navbar">
      <CssBaseline />
      <AppBar position="static" color="inherit" elevation={0}>
        <MuiToolbar className="mui-toolbar" pathname={location.pathname}>
          <Box className="logo">
            <Link to={"/"}>
              <img src={Logo} alt="logo" />
            </Link>
          </Box>
          {!loading && !isMobile && (
            <Box className="middleRoutes">
              {middleRoutes
                .filter((route) =>
                  route.title === "Contact" && location.pathname === "/login"
                    ? true
                    : route.authenticated === authIsReady
                )
                .map((route) =>
                  route.show === "team" ? (
                    <MuiLink
                      key={route.to}
                      onClick={() => {
                        if (route.to.includes("#")) {
                          location.pathname !== "/" && navigate("/");
                          return setTimeout(() => {
                            scrollToElement(route.to);
                          }, 20);
                        }
                        navigate(route.to);
                      }}
                      className={location.pathname === route.to ? "active" : ""}
                    >
                      {route.title}
                    </MuiLink>
                  ) : (
                    <MuiLink
                      key={"/upgrade"}
                      onClick={() => navigate("/upgrade")}
                      className={
                        location.pathname === "/upgrade" ? "active" : ""
                      }
                    >
                      Team
                    </MuiLink>
                  )
                )}
            </Box>
          )}
          <Box className="rightRoutes">
            {!loading
              ? isMobile
                ? !authIsReady && (
                    <Stack direction="row" spacing={1.2}>
                      {showLogins && <LoginRoutes />}
                      <IconButton
                        color="default"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleMenuClick}
                        aria-controls={openMenu ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                      >
                        <MenuIcon />
                      </IconButton>
                    </Stack>
                  )
                : !authIsReady && <LoginRoutes />
              : null}
            {!loading && authIsReady && currentUser?.email ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: { xs: "3px", sm: "5px" },
                }}
              >
                {/* <IconButton>
                                    <SearchIcon />
                                </IconButton> */}
                <Tooltip title="Notifications">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={openNoti ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openNoti ? "true" : undefined}
                  >
                    <Badge
                      badgeContent={receivedNoti?.filter((v) => !v.seen).length}
                      color="error"
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <AccountMenu
                  anchorEl={anchorElNoti}
                  setAnchorEl={setAnchorElNoti}
                  openNoti={openNoti}
                  receivedNoti={receivedNoti}
                  uid={currentUser?.uid}
                />
                <Box
                  sx={{
                    pl: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Button
                    onClick={handleMenuClick}
                    aria-controls={openMenu ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    sx={{
                      minWidth: "auto",
                      color: (theme) => theme.palette.text.primary,
                      borderRadius: { xs: "50%", sm: "3px" },
                    }}
                    endIcon={
                      showLogins ? (
                        openMenu ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      ) : null
                    }
                    size="small"
                    variant="text"
                    // color="secondary"
                  >
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar
                        sx={{
                          width: 38,
                          height: 38,
                          fontSize: "0.9rem",
                          cursor: "pointer",
                          p: 0,
                        }}
                        src={currentUser?.photoURL || ""}
                        alt={
                          currentUser?.displayName?.toUpperCase() ||
                          currentUser?.email?.toUpperCase()
                        }
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          textTransform: "capitalize",
                        }}
                      >
                        {showLogins && (currentUser?.displayName || "...")}
                      </Typography>
                    </Stack>
                  </Button>
                </Box>
              </Box>
            ) : null}
          </Box>
        </MuiToolbar>
      </AppBar>
      <UserMenu
        open={openMenu}
        anchorEl={anchorEl}
        currentUser={currentUser}
        handleClose={handleMenuClose}
        handleLogout={handleLogout}
        middleRoutes={middleRoutes}
        scrollToElement={scrollToElement}
        isMobile={isMobile}
        showLogins={showLogins}
        LoginRoutes={() => <LoginRoutes className="innerRoutes" />}
      />
    </Box>
  );
};

export default Navbar;
