import { useCollection } from '../../hooks/useCollection'
import { useAuthContext } from '../../hooks/useAuthContext'
import { Link } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button, Container, Typography } from '@mui/material'
import Ads from '../../components/Ads/Ads'
import { getCustomClaimRole } from '../../firebase'
import { useTeamOwnersContext } from '../../hooks/useTeamOwnersContext'
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { useSnackbar } from 'notistack'
import WelcomeBox from './components/WelcomeBox'
import CircularLoader from '../../components/CircularLoader/CircularLoader'
import adsImg1 from '../../images/ads1.png'
import adsImg2 from '../../images/ads2.png'

const MainContainer = styled(Box)(({ theme }) => ({
  // minHeight: "85vh",
  padding: "2rem",
  [theme.breakpoints.down("sm")]: {
    padding: "2rem 1rem"
  },
  "& .MuiOutlinedInput-root": {
    paddingLeft: 8,
    "& input": {
      padding: "11px 8px"
    }
  },

}))

const Dashboard = () => {
  const { user } = useAuthContext()
  const { isTeamMember } = useTeamOwnersContext()
  const { enqueueSnackbar } = useSnackbar()
  const [projectsExists, setProjectExists] = useState(false)
  const [loading, setLoading] = useState(true)
  const [userRole, setUserRole] = useState("")
  const [roleLoading, setRoleLoading] = useState(true)


  useEffect(() => {
    (async _ => {
      // eslint-disable-next-line array-callback-return
      let role = await getCustomClaimRole()
      setUserRole(role)
      setRoleLoading(false)
      const projCol = collection(db, "projects");
      const q1 = query(projCol, where("uid", "==", user.uid))
      const unsub = onSnapshot(q1, async snap => {
        setProjectExists(snap.docs.length > 0)
        setLoading(false)
      }, error => {
        enqueueSnackbar(error.message, { variant: "error" })
        setLoading(false)
        setRoleLoading(false)
      })
      return () => unsub()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])


  let showAds = !roleLoading && (userRole !== "team" && (!isTeamMember));
  if (loading || roleLoading) {
    return <CircularLoader />
  }
  return (
    <Box sx={{ bgcolor: "#F8FAFC", width: "100%" }}>
      <MainContainer>
        <Box
          sx={{ display: "flex", minHight: "60vh",alignItems:{sm:"start"}, flexDirection: { xs: "column", sm: "row", md: "row" }, gap: "1rem" }}
        >
          <a href="https://sendmea.io/register?fpr=akm" target='_blank'>
          <Ads
            sx={{ width: { xs: "85%", sm: "200px" }, height: { xs: "13vh", sm: "60vh" } }}
            roleLoading={roleLoading}
            showAds={showAds}
            adsImg={adsImg1}
            />
          </a>
          <WelcomeBox projectsExists={projectsExists} />
        </Box>
        <a href="https://sendmea.io/register?fpr=akm" target='_blank'>
        <Ads
          sx={{ width: { xs: "85%", sm: "70%", }, height: { xs: "13vh", sm: "15vh" }, mt: 4, mb: 3, mx: "auto" }}
          roleLoading={roleLoading}
          showAds={showAds}
          adsImg={adsImg2}
        />
        </a>
      </MainContainer>
    </Box>
  )
}

export default Dashboard
