import { createContext, useReducer, useEffect } from 'react'
import { auth, db } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth'
import { doc, onSnapshot } from 'firebase/firestore'

export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload }
    case 'LOGOUT':
      return { ...state, user: null, authIsReady: false }
    case 'AUTH_IS_READY':
      return { user: action.payload, authIsReady: true, loading: false }
    case 'AUTH_IS_NOT_READY':
      return { ...state, authIsReady: false }
    case 'REMOVE_LOADING':
      return { ...state, loading: false }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
    loading: true
  })

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid)
        onSnapshot(userRef, (userSnap) => {
          dispatch({ type: 'AUTH_IS_READY', payload: { ...user, ...userSnap.data(), uid: userSnap.id } })
        })
      }
      else {
        dispatch({ type: 'REMOVE_LOADING' })
        dispatch({ type: 'LOGOUT' })
      }
      unsub()
    })
  }, [])

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}
