import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useCollection } from '../../hooks/useCollection'
import { db } from '../../firebase/config'
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";



const ConfirmTeamInvite = () => {

    const params = useParams()
    const [member, setMember] = useState(null)
    const { documents: users } = useCollection('users')

    const { inviterId, inviteeId } = params

    const userTeamsRef = doc(db, "users", inviterId);


    const addInvitee = async () => {
        const check = users.find(v => v.id === inviterId && v.team)
        const isExist = check?.team.filter((v) => v.id === inviteeId)

        try {
            if (isExist.length > 0) {
                return console.log("You're Already added to a team")
            }
            else {
                // Atomically add a new region to the "regions" array field.
                const myMan = users.find(v => v.id === inviteeId)
                await updateDoc(userTeamsRef, {
                    team: arrayUnion(myMan)
                });
                console.log("Congratulations! You're added to a team")
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        users && addInvitee()
    }, [users])




    return (
        <div>
            <p>inviterId :{inviterId}</p>
            <p>initeeId :{inviteeId}</p>
        </div>
    )
}

export default ConfirmTeamInvite