import {
  Box,
  styled,
  Typography,
  IconButton,
  Avatar,
  Button,
  Modal,
  Tooltip,
} from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { Link, useNavigate } from "react-router-dom";
import BasicMenu from "../../../components/BasicMenu/BasicMenu";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { db } from "../../../firebase/config";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { useTeamOwnersContext } from "../../../hooks/useTeamOwnersContext";
// import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileModal from "../ProjectDetails/components/FileModal";
const MainContariner = styled(Box, {
  shouldForwardProp: (prop) => prop !== "index",
})(({ theme }) => ({
  position: "relative",
  minHeight: 230,
  width: "100%",
  padding: theme.spacing(2, 0),
  boxShadow: "rgb(0 0 0 / 5%) 0px 0px 5px 1px",
  backgroundColor: "#FFFFFF",
  height: "100%",
  "& .top": {
    display: "flex",
    alignItems: "center",
  },
  "& .projectTitle": {
    textTransform: "capitalize",
    paddingLeft: theme.spacing(3),
    color: "#000",
    fontWeight: 500,
    fontSize: 16,
    borderLeft: "2px solid #4A3AFF",
    flex: 1,
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "2px",
  boxShadow: 24,
  py: 2,
  px: 3,
  outline: "none",
  "& div": {
    display: "flex",
    justifyContent: "space-around",
    "& button": {
      mt: "1rem",
      width: 100,
    },
  },
};
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" arrow />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    //   maxWidth: 500,
    backgroundColor: "#4A3AFF",
    fontSize: "15px",
    border: "1px solid #4A3AFF",
  },
  [`& .${tooltipClasses.arrow}`]: {
    //   maxWidth: 500,
    color: "#4A3AFF",
  },
});
// const useStyles = makeStyles({
//   arrow: {
//     color: "red",
//   },
// });
const ProjectCard = ({
  projectDetails,
  index,
  isOwner,
  myTeam,
  user,
  userRole,
}) => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const { teamOwners, dispatch } = useTeamOwnersContext();
  const [detailLength, setdetailLength] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [teamMembers, setTeamMembers] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openFile, setFileOpen] = useState(false);
  const getTeamMembers = async () => {
    if (projectDetails.uid === user.uid) {
      const getTeam = myTeam
        ? projectDetails?.team.map((memberid) =>
            myTeam.find((v) => v.uid === memberid)
          )
        : [];
      setTeamMembers(getTeam);
    } else {
      const getTeam = await Promise.all(
        projectDetails?.team?.map(async (memberId) => {
          let memberUserObj = await getDoc(doc(db, "users", memberId));
          memberUserObj = { ...memberUserObj.data(), uid: memberUserObj.id };
          return memberUserObj;
        })
      );
      setTeamMembers(getTeam);
    }
  };
  useEffect(() => {
    projectDetails.project_type === "team" && getTeamMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTeam, projectDetails]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setdetailLength(projectDetails.project_info?.length > 65);
  }, [projectDetails]);

  const deleteProj = async (id) => {
    const docRef = doc(db, "projects", id);
    await deleteDoc(docRef);
  };
  const handleDeleteOpen = () => setOpenDelete(true);
  const handleDeleteClose = () => setOpenDelete(false);
  const options = [
    {
      name: "View",
      icon: <VisibilityOutlinedIcon fontSize="small" />,
      linkTo: `/projects/${projectDetails.id}`,
    },
    {
      name: "Add Member",
      icon: <AddCircleOutlineOutlinedIcon fontSize="small" />,
      linkTo: `/update-project/${projectDetails.id}`,
      state: projectDetails,
      disabledInvite:
        (!isOwner &&
          myTeam?.filter((v) => v?.uid === user?.uid)?.[0]?.teamManager ===
            false) ||
        projectDetails?.project_type !== "team",
      show: userRole === "team",
    },
    {
      name: "Edit",
      icon: <img src="/icons/_edit.png" alt="edit" />,
      linkTo: `/update-project/${projectDetails.id}`,
      disabledInvite:
        !isOwner &&
        myTeam?.filter((v) => v?.uid === user?.uid)?.[0]?.teamManager === false,
      state: projectDetails,
    },
    {
      name: "Delete",
      icon: <img src="/icons/dlt.svg" alt="edit" />,
      // linkTo: `/projects/${projectDetails.id}`,
      disabledInvite:
        !isOwner &&
        myTeam?.filter((v) => v?.uid === user?.uid)?.[0]?.teamManager === false,
      sx: { color: "#FF3559" },
      onClick: () => handleDeleteOpen(),
    },
  ];
  return (
    <MainContariner key={index} index={index}>
      <Box className="top">
        <Typography
          component={Link}
          to={`/projects/${projectDetails.id}`}
          className="projectTitle"
        >
          {" "}
          {projectDetails.project_name}
        </Typography>
        <Box sx={{ px: 2 }}>
          <IconButton
            aria-label="file download"
            sx={{ "&>span": { fontSize: "small" } }}
            onClick={() => setFileOpen(true)}
          >
            <span
              style={{
                color:
                  projectDetails?.files &&
                  projectDetails?.files?.length > 0 &&
                  "#00AE96",
              }}
            >
              {projectDetails?.files ? projectDetails?.files?.length : "0"}
            </span>
            <img
              src="/icons/file-download.svg"
              style={{
                filter:
                  projectDetails?.files &&
                  projectDetails?.files?.length > 0 &&
                  "invert(12%) sepia(15%) saturate(6192%) hue-rotate(239deg) brightness(100%) contrast(156%)",
              }}
              alt="file download"
            />
          </IconButton>
          <IconButton aria-label="more" onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <CustomTooltip
        title={`Team: ${
          user?.managerInfo?.teamName ||
          teamOwners?.map((i) => i.owner?.managerInfo?.teamName)
        }`}
        // classes={{ arrow: classes.arrow }}
        arrow
        placement="top"
      >
        <Typography
          variant="body2"
          color={"primary"}
          sx={{
            px: 3,
            fontWeight: 500,
            position: "relative",
            bottom: 5,
            textTransform: "capitalize",
            display: "inline-block",
          }}
        >
          {projectDetails?.project_type} Project
        </Typography>
      </CustomTooltip>
      <Typography variant="body2" sx={{ px: 3, minHeight: 50 }}>
        {projectDetails.project_info ? (
          <span style={{ textTransform: "capitalize" }}>
            {projectDetails.project_info?.substring(0, 65)}
            {detailLength ? (
              <Typography
                display={"inline"}
                component={Link}
                to={`/projects/${projectDetails.id}`}
              >
                ...
              </Typography>
            ) : (
              ""
            )}
          </span>
        ) : (
          "Not available!"
        )}
      </Typography>
      {projectDetails.project_type === "team" &&
      projectDetails?.team &&
      projectDetails?.team.length ? (
        <Box sx={{ mx: 3, py: 2 }}>
          {teamMembers
            ?.filter((_v, i) => i < 6)
            ?.map((v, i) => (
              <CustomTooltip title={v?.displayName || "no name"}>
                <Avatar
                  key={v?.id || i}
                  src={v?.photoURL || " "}
                  alt={v?.displayName?.toUpperCase() || v?.email?.toUpperCase()}
                  sx={{
                    backgroundColor: "white",
                    border: "1px solid #FFFFFF",
                    position: "absolute",
                    bottom: 15,
                    left: (i + 1) * 20,
                  }}
                />
              </CustomTooltip>
            ))}
          {userRole === "team" && isOwner && (
            <Button
              variant="text"
              color="inherit"
              startIcon={<AddIcon />}
              // component={Link}
              // to={`/update-project/${projectDetails.id}`}
              onClick={() =>
                navigate(`/update-project/${projectDetails.id}`, {
                  state: projectDetails,
                })
              }
              sx={{
                position: "absolute",
                bottom: 15,
                left: (teamMembers?.filter((_v, i) => i < 3).length + 1) * 45,
              }}
              disabled={!isOwner || projectDetails?.project_type !== "team"}
            >
              Add More
            </Button>
          )}
        </Box>
      ) : (
        !options[1].disabledInvite &&
        userRole === "team" &&
        isOwner && (
          <Box sx={{ mx: 3, py: 2 }}>
            <IconButton
              aria-label="add team"
              sx={{
                backgroundColor: "#D9DBE9",
                p: 0.5,
                position: "absolute",
                bottom: 15,
                left: 25,
              }}
              onClick={() =>
                navigate(`/update-project/${projectDetails.id}`, {
                  state: projectDetails,
                })
              }
              disabled={!isOwner || projectDetails?.project_type !== "team"}
            >
              <AddIcon color="primary" />
            </IconButton>
            <Typography
              variant="body2"
              sx={{ px: 1, position: "absolute", bottom: 19, left: 60 }}
            >
              Add Team Member
            </Typography>
          </Box>
        )
      )}
      <BasicMenu
        open={open}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleClose={handleClose}
        options={options}
        handleDeleteOpen={handleDeleteOpen}
      />
      <Modal
        open={openDelete}
        onClose={() => {
          handleDeleteClose();
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6">
            Are you sure? this will delete it permanently!
          </Typography>
          <Box>
            <Button variant="outlined" onClick={handleDeleteClose}>
              No
            </Button>
            <Button
              variant="contained"
              onClick={() => deleteProj(projectDetails.id)}
            >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
      {Object.keys(projectDetails).length > 0 && (
        <FileModal
          project={projectDetails}
          user={user}
          openFile={openFile}
          setFileOpen={setFileOpen}
        />
      )}
    </MainContariner>
  );
};

export default ProjectCard;
