import { useState } from 'react'
import { db } from '../firebase/config'
import { doc, updateDoc } from 'firebase/firestore'

export const useUpdate = () => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    // const { enqueueSnackbar } = useSnackbar();

    const updateSetting = async ({ uid }, dbKey, other) => {
        setError(null)
        setLoading(true)
        try {
            const docRef = doc(db, dbKey, uid)
            await updateDoc(docRef, {
                ...other
            })
            setLoading(false)
            // enqueueSnackbar("Data Updated!", { variant: "success" });
        }
        catch (error) {
            setError(error)
            setTimeout(() => {
                setError("")
            }, 5000);
            setLoading(false)
        }
    }


    return { error, loading, updateSetting }
}
