import { auth, db } from "./config";
import { collection, getDocs, where, query, onSnapshot, doc, getDoc, addDoc, setDoc } from 'firebase/firestore';

export const getCustomClaimRole = async () => {
    await auth.currentUser.getIdToken(true);
    const decodedToken = await auth.currentUser.getIdTokenResult();
    return decodedToken.claims.stripeRole;
}

export const getPlans = async () => {
    const docRef = query(collection(db, 'products'), where('active', '==', true))
    const productsSnapshot = await getDocs(docRef)
    const plans = await Promise.all(productsSnapshot.docs.map(async doc => {
        const obj = { ...doc.data(), docId: doc.id }
        const priceSnap = await getDocs(collection(doc.ref, 'prices'));
        obj.prices = priceSnap.docs.filter(doc => doc.data().active).map(doc => ({ ...doc.data(), docId: doc.id }))
        return obj
    }))
    return plans.reverse().slice(1)
}

export const subscribeToPlan = async (priceDocId, close = () => { }) => {
    try {

        const docRef = doc(db, `users/${auth.currentUser.uid}/checkout_sessions`, Date.now().toString())
        await setDoc(docRef, {
            price: priceDocId,
            success_url: window.location.origin + "/dashboard",
            cancel_url: window.location.origin + "/dashboard",
        })

        onSnapshot(docRef, (snap) => {
            const { error, url } = snap.data();
            if (error) {
                close()
                console.log(`An error occured: ${error.message}`);
            }
            if (url) {
                window.location.assign(url);
            }
        })
    } catch (err) {
        close()
        console.log(err.message)
    }
}