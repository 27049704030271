import { useState } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import { db, auth } from '../firebase/config'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { collection, doc, addDoc, setDoc } from 'firebase/firestore'

export const useRegister = () => {
  const [error, setError] = useState(null)
  const { dispatch } = useAuthContext()

  const addUser = async (user) => {
    let { uid, email, displayName, photoURL } = user
    console.log({ user })
    const docRef = doc(db, 'users', uid)
    await setDoc(docRef, {
      email,
      displayName,
      photoURL,
    })
  }

  const signup = async (data, handleRegisterSuccess) => {
    setError(null)

    try {
      let { email, password, ...rest } = data
      const res = await createUserWithEmailAndPassword(auth, email, password,)
      await addUser({ ...res.user, ...rest })
      dispatch({ type: 'AUTH_IS_READY', payload: { ...res.user, ...rest } })
      handleRegisterSuccess()
    } catch (error) {
      setError(error)
      setTimeout(() => {
        setError("")
      }, 4500);
    }
  }

  return { error, signup, addUser }
}
