import { Box, Button, Grid, IconButton, InputAdornment, styled, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { useTeamOwnersContext } from '../../../hooks/useTeamOwnersContext'
import ProjectCard from '../ProjectCard/ProjectCard'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import Ads from '../../../components/Ads/Ads'
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import WelcomeBox from '../../dashboard/components/WelcomeBox'
import { useSnackbar } from 'notistack'
import CircularLoader from '../../../components/CircularLoader/CircularLoader'
import { useMyTeamContext } from '../../../hooks/useMyTeamContext'
import adsImg1 from '../../../images/ads1.png'
import adsImg2 from '../../../images/ads2.png'


const MainContainer = styled(Box)(() => ({
    minHeight: "85vh",
    width: "100%",
    "& .MuiOutlinedInput-root": {
        paddingLeft: 8,
        "& input": {
            padding: "11px 8px"
        }
    },
    "& .MuiButton-contained": {
        padding: "10px 2rem"
    }
}))

function ProjectDashboard({ userRole }) {
    const { user } = useAuthContext()
    const { enqueueSnackbar } = useSnackbar()
    const { myTeam } = useMyTeamContext()
    const { teamOwners } = useTeamOwnersContext()

    const [searchValue, setSearchValue] = useState("")
    const [projectState, setProjectState] = useState([])
    const [projects, setProjects] = useState([])
    const [projectsLoading, setProjectsLoading] = useState(true);

    useEffect(() => {
        (async _ => {
            // eslint-disable-next-line array-callback-return
            const projCol = collection(db, "projects");
            const q1 = query(projCol, where("uid", "==", user.uid))
            const unsub = onSnapshot(q1, async snap => {
                let results = []
                snap.docs.forEach(proj => results.push({ ...proj.data(), id: proj.id }))
                const q2 = query(projCol, where("team", "array-contains", user.uid))
                let snap2 = await getDocs(q2)
                snap2.docs.filter(proj2 => {
                    return proj2.data().project_type !== "solo" && results.push({ ...proj2.data(), id: proj2.id })
                })
                setProjects(results)
                setProjectsLoading(false)
            }, error => {
                enqueueSnackbar(error.message, { variant: "error" })
                setProjectsLoading(false)
            })
            return () => unsub()
        })()
    }, [enqueueSnackbar, user, projects])

    useEffect(() => {
        if (searchValue && projects.length > 0) {
            setProjectState(projects.filter((v) => v.project_name?.toLowerCase()?.startsWith(searchValue)))
        } else {
            setProjectState(projects)
        }
    }, [searchValue, projects])


    // useEffect(() => {
    //     (async _ => {
    //         if (loadData && allUsers) {
    //             allUsers.forEach(async user => {
    //                 if (user.team.length > 0) {
    //                     // var object = user.team.reduce((obj, item) => (obj[item.email] = item, obj), {});
    //                     console.log({ [user.displayName]: user })
    //                     user.team.forEach(async member => {
    //                         const teamMmeberRef = doc(db, `users/${user.id}/team`, member.email)
    //                         await setDoc(teamMmeberRef, member)
    //                     })
    //                 }
    //             })
    //             setLoadData(false)
    //         }
    //     })()
    // }, [loadData, allUsers])

    // useEffect(() => {
    //     console.log({ teamOwners, isTeamMember })
    // }, [teamOwners, isTeamMember])


    const InputEndAdornment = (
        <InputAdornment position="end" sx={{ cursor: "pointer" }}>
            {searchValue &&
                <IconButton
                    size='small'
                    onClick={() => {
                        setSearchValue("");
                        setProjectState(projects)
                    }}
                    sx={{ transition: "display 0.2s ease", display: "flex" }}
                >
                    <CloseIcon />
                </IconButton>
            }
        </InputAdornment>
    );

    const gridProps = {
        projectContainer: {
            xs: 12,
            ...(userRole === "team" || teamOwners.length > 0 ?
                { sm: 12 } :
                { sm: 9, md: 10 }
            ),
        },
        project: {
            xs: 12,
            ...(userRole === "team" || teamOwners.length > 0 ?
                { sm: 6, md: 4, lg: 3, } :
                { sm: 6, md: 4 }
            ),
        },
    }

    if (projectsLoading) {
        return <CircularLoader />
    }

    return (
        <MainContainer sx={{ py: "30px", px: 3, bgcolor: "#F8FAFC" }}>
            <Box display={"flex"} mb={{ xs: 3, sm: 0 }}>
                <Typography sx={{ color: "#000", fontWeight: 700, fontSize: 24, mb: { xs: 5, md: 3 }, flex: 1 }}>Projects</Typography>
                <Box sx={{ display: { xs: "flex", sm: "contents", lg: "flex" }, flexDirection: { xs: "column-reverse", sm: "row" }, alignItems: "stretch", flex: 0.8, gap: "2rem" }}>
                    <TextField variant='outlined' placeholder='Search Project'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value?.toLowerCase())}
                        sx={{
                            flex: 1,
                            pr: 2,
                            mt: { xs: 2, sm: 0 }, width: { xs: "93.4vw", sm: "initial" },
                            right: 8,
                            position: { xs: "absolute", sm: "initial" }
                        }}
                        fullWidth

                        InputProps={{
                            endAdornment: InputEndAdornment,
                            startAdornment: (
                                <IconButton size='small'>
                                    <SearchIcon color="default" />
                                </IconButton>
                            )
                        }}
                    />

                    <Button variant="contained" sx={{ whiteSpace: "nowrap", height: "max-content", mb: { xs: "4rem" } }} startIcon={<AddIcon />} component={Link} to='/add-project' >New Project</Button>
                </Box>
            </Box>
            <Grid container spacing={2} height="100%" alignItems="flex-start">
                {teamOwners.length === 0 && (userRole !== "team" &&
                    <Grid item xs={12} sm={3} md={2}>

                    <a href="https://sendmea.io/register?fpr=akm" target='_blank'>
                        <Ads
                            sx={{ width: "100%", height: { xs: "13vh", sm: "60vh" } }}
                            roleLoading={!userRole}
                            adsImg={adsImg1}
                            showAds={userRole !== "team" && (teamOwners.length === 0 ? true : false)}
                            />
                    </a>
                    </Grid>
                )}
                <Grid item container {...gridProps.projectContainer} spacing={2}>
                    {projects.length > 0 && projectState.length > 0 ?
                        projectState.map((project, index) => (
                            <Grid item {...gridProps.project} key={project.id + index} sx={{ boxSizing: "border-box", }}>
                                <ProjectCard
                                    userRole={userRole}
                                    index={index}
                                    myTeam={myTeam}
                                    projectDetails={project}
                                    isOwner={project.uid === user.uid}
                                    disabled={project.uid !== user.uid}
                                    user={user}
                                    />
                            </Grid>
                        )) :
                        <Grid item xs={12} mt={3}>
                            <WelcomeBox
                                projectsExists={projects.length}
                                title="You don't have any Project"
                                />
                        </Grid>
                    }
                </Grid>
                {teamOwners.length === 0 && (userRole !== "team" &&
                    <Grid item xs={12} sm={10} sx={{ mt: 5, mb: 3, mx: "auto" }}>

                        <a href="https://sendmea.io/register?fpr=akm" target='_blank'>

                        <Ads
                            sx={{ width: "100%", height: { xs: "13vh", sm: "18vh" } }}
                            roleLoading={!userRole}
                            adsImg={adsImg2}
                            showAds={userRole !== "team" && (teamOwners.length === 0 ? true : false)}
                        />
                        </a>
                    </Grid>
                )}
            </Grid>
        </MainContainer>
    )
}

export default ProjectDashboard