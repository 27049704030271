import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: "#4A3AFF",
            light: "#6b5efa",
            warning: "#ffcd38"
        },
        secondary: {
            main: "#F3F1FF",
            light: "#FFFFFF",

        },
        neutral: {
            "500": "#A0A3BD",
            "600": "#6E7191",
            "700": "#4E4B66",
            "800": "#170F49",
        },
        text: {
            primary: "#6F6C90",
            secondary: "#667085"
        }

    },
    typography: {
        fontFamily: `DM Sans`,
        h1: {
            fontSize: "64px",
            fontWeight: "700",
            '@media (max-width:900px)': {
                fontSize: '58px',
            },
            '@media (max-width:600px)': {
                fontSize: '52px',
            },
        },
        h2: {
            fontSize: "44px",
            fontWeight: "700",
            '@media (max-width:900px)': {
                fontSize: '38px',
            },
            '@media (max-width:600px)': {
                fontSize: '32px',
            },
        },
        h3: {
            fontSize: "34px",
            fontWeight: "700",
            '@media (max-width:900px)': {
                fontSize: '30px',
            },
            '@media (max-width:600px)': {
                fontSize: '28px',
            },
        },
        h4: {
            fontSize: "34px",
            fontWeight: "500",
            '@media (max-width:900px)': {
                fontSize: '30px',
            },
            '@media (max-width:600px)': {
                fontSize: '28px',
            },
        },
        h5: {
            fontSize: "24px",
            fontWeight: "500",
            '@media (max-width:900px)': {
                fontSize: '22px',
            },
            '@media (max-width:600px)': {
                fontSize: '20px',
            },
        },
        h6: {
            fontSize: "20px",
            fontWeight: "500"
        },
        body1: {
            fontSize: "18px",
            '@media (max-width:600px)': {
                fontSize: '16px',
            },
        },
        body2: {
            fontSize: "16px",
            '@media (max-width:600px)': {
                fontSize: '14px',
            },
        },
        caption1: {
            fontSize: "14px",
            '@media (max-width:600px)': {
                fontSize: '12px',
            },
        },
        caption2: {
            fontSize: "12px",
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "2px",
                }
            },
        },
        MuiInputBase: {
            defaultProps: {
                sx: {
                    borderRadius: "2px",
                    // border: "1.5px solid #E4E4E7",
                    backgroundColor: "#FFFFFF",
                    ":hover, :active, :focus": {
                        backgroundColor: "#FFFFFF",
                    },
                    "& input": {
                        borderRadius: "2px",
                        backgroundColor: "#FFFFFF",
                    }
                },
            }
        }
    },
});
