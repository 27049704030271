import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';


const BasicBreadcrumbs = ({ list }) => {
    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }
    return (
        <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb">
                {list?.map((v, i) => {
                    if (list.length - 1 === i) {
                        return <Typography key={i + v.title} color="text.primary">{v.title}</Typography>
                    } else {
                        return <Link key={i + v.title} underline="hover" color="inherit" component={RouterLink} to={v.url}>
                            {v.title}
                        </Link>
                    }
                })}
            </Breadcrumbs>
        </div>
    );
}

export default BasicBreadcrumbs;