import React from 'react'
import { Grid, Box, Typography, Divider, Container, Stack, Avatar } from '@mui/material'
import { styled } from '@mui/material/styles'
import StarIcon from '@mui/icons-material/Star';
import user1 from "../../images/user1.png"
import user2 from "../../images/user2.png"

const StyledTestimonals = styled(Box)(({ theme }) => ({
    padding: "4rem 3rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    background: "#fff",
    [theme.breakpoints.down("md")]: {
        padding: "3rem 2rem",
    },
    [theme.breakpoints.down("sm")]: {
        padding: "3rem 0.8rem",
        gap: "1rem",

    },
    "& >.MuiTypography-root.clients": {
        fontSize: 24,
        lineHeight: "30px",
        textAlign: "center",
        color: theme.palette.primary.main,
        [theme.breakpoints.down("md")]: {
            fontSize: 22,
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 20,
        },
    },
    "& >.MuiTypography-root.desc": {
        lineHeight: "40px",
        textAlign: "center",
        fontSize: 40,
        color: "#000",
        [theme.breakpoints.down("md")]: {
            fontSize: 36,
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: 30,
        },
    },

    "& .tstContainer": {
        display: "flex",
        flexWrap: "wrap",
        gap: "4rem",
        maxWidth: "1250px",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            flexWrap: "nowrap",
        },
        [theme.breakpoints.down("md")]: {
            gap: "2rem",
        }
    }
}))

const ClientBox = styled(Stack)(({ theme }) => ({
    minWidth: "340px",
    "& > .MuiAvatar-root": {
        width: "9em",
        height: "100%",
        borderRadius: "5px",
        [theme.breakpoints.down("md")]: {
            width: "190px",
            height: "200px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "170",
        },
    },

    "& > .info": {
        flex: 1,
        "& > .icons": {
            display: "flex",
            gap: "4px",
            "& svg": {
                [theme.breakpoints.down("sm")]: {
                    fontSize: 20,
                }
            }
        },
        "& .MuiTypography-root.review": {
            fontSize: 18,
            [theme.breakpoints.down("sm")]: {
                fontSize: 16,
                lineHeight: "22px",
                textAlign: "center",

            }
        },
        "& .bottom": {
            display: "flex",
            justifyContent: "flex-start",
            gap: "2rem",
            width: "90%",
            [theme.breakpoints.down("sm")]: {
                justifyContent: "center",
            }

        },
        "& .bottom .MuiTypography-root": {
            fontSize: 16,
            [theme.breakpoints.down("sm")]: {
                fontSize: 14,
            }
        }
    }
}))


const Testimonials = () => {

    const reviewTxt = "We love Cred Management! Our designers were using it for their projects, so we already knew what kind of design they want."

    const testimonalData = [
        { photo: user1, name: "Jenny Wilson", website: "Grower.io", reviewTxt, rating: 5 },
        { photo: user2, name: "Dave Lame", website: "DLD Design.co", reviewTxt, rating: 5 }
    ]

    return (
        <StyledTestimonals id="testimonials">
            <Typography className='clients' variant='h4'>3940+ Happy Cred Management Users</Typography>
            <Typography className='desc' variant='h2'>Don’t just take our words</Typography>
            <Box className='tstContainer' mt={4}>
                {testimonalData.map(client => (
                    <ClientBox
                        direction={{ xs: "column", sm: "row", md: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 3 }}
                        alignItems={{ xs: "center", sm: "stretch", }}
                        key={client.name}
                    >
                        <Avatar src={client.photo} alt="user" />
                        <Stack
                            className='info'
                            direction="column"
                            maxWidth={{ xs: 270, sm: 380, md: 300 }}
                            justifyContent="space-between"
                            alignItems={{ xs: "center", sm: "flex-start" }}
                            spacing={{ xs: 1, sm: 2, md: 2 }}
                            paddingY={0.5}
                        >
                            <Box className='icons'>
                                {[...Array(client.rating).keys()].map(no => (
                                    <StarIcon color='primary' key={no} />
                                ))}
                            </Box>
                            <Typography className='review' fontSize={18}>{reviewTxt}</Typography>
                            <Box className='bottom'>
                                <Typography fontSize={16} fontWeight={700} color="#170F49">{client.name}</Typography>
                                <Typography fontSize={16}>{client.website}</Typography>
                            </Box>
                        </Stack>
                    </ClientBox>
                ))}

            </Box>
        </StyledTestimonals >
    )
}

export default Testimonials