import { TeamOwnersContext } from '../context/TeamOwnersContext'
import { useContext } from 'react'

export const useTeamOwnersContext = () => {
    const context = useContext(TeamOwnersContext)

    if (!context) {
        throw Error('useTeamOwnersContext must be used inside an TeamOwnersContextProvider')
    }

    return context
}
