import '../index.css'

const NotFound = () => {
  return (
    <div className='not_found'>
      <img
        src='https://res.cloudinary.com/codeshock/image/upload/v1651809727/404_gowkaw.webp'
        alt='not found'
      />
    </div>
  )
}

export default NotFound
