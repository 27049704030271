
import React, { useEffect, useState } from 'react'
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { Backdrop, CircularProgress } from "@mui/material"
import CircularLoader from './components/CircularLoader/CircularLoader';


const ProtectedRoutes = ({ dashbaordRoutes, loginRoutes, stripeLoader }) => {
    const { user, authIsReady, loading } = useAuthContext()
    const location = useLocation();
    const [redirectTo, setRedirectTo] = useState(null);

    const checkSearch = () => {
        if (location.search) {
            let redirectTo = location.search.split('redirectTo=')
            return setRedirectTo(redirectTo[1])
        }
        else if (location.state && location?.state?.from?.search) {
            let redirectTo = location?.state?.from?.search.split('redirectTo=')
            return setRedirectTo(redirectTo[1])
        }
        else return setRedirectTo("");
    }

    useEffect(() => {
        return checkSearch()
    }, [])


    if (loading) {
        return (
            <CircularLoader />
        )
    }

    if (dashbaordRoutes) {
        return (
            stripeLoader ? <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={stripeLoader}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop> :
                user && authIsReady
                    ? <Outlet />
                    : <Navigate to={redirectTo ? `/${redirectTo}` : "/login"} state={{ from: location }} replace />
        );
    }
    if (loginRoutes) {
        return (
            !user && !authIsReady
                ? <Outlet />
                : <Navigate to="/projects" state={{ from: location }} replace />
        );
    }
}

export default ProtectedRoutes;