import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

const StyledAd = styled(Box)(({ theme }) => ({
    backgroundColor: "#FFFFFF",
    boxShadow: "rgb(0 0 0 / 5%) 0px 0px 5px 1px",
    position: "relative",
    width: "200px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

}))
const Ads = ({ sx, showAds,adsImg }) => {

    return (
        <StyledAd sx={{ height: "20vh", ...sx, display: (showAds ? "flex" : "none"),
        }} >
            {/* <Typography variant='body2' >Coming Soon...</Typography> */}
            <img src={adsImg} height="100%" width="100%" />
        </StyledAd>
    )
}

export default Ads