import React, { forwardRef, useEffect } from 'react'
import { FormControlLabel, Checkbox,Container, DialogActions, DialogContent, Slide, Stack, Box, Dialog, Button, Typography, DialogTitle, FormControl, FormLabel, TextField, Grid, FormHelperText, CircularProgress } from '@mui/material'

import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { doc, updateDoc } from 'firebase/firestore';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useForm, Controller  } from 'react-hook-form';
import { db } from '../../../firebase/config';

const MainContainer = styled(Container)(() => ({
    width: "100%",
}))
const StyledDialog = styled(Dialog)(() => ({

    "& .MuiDialogContent-root": {
        "& .MuiFormControl-root": {
            "& .MuiFormLabel-root": {
                color: "#000",
                fontWeight: 500,
            },
            "& .MuiOutlinedInput-root": {
                borderRadius: 2,
                "& input": {
                    padding: "11px 14px",
                },
            },
            "& > p.MuiFormHelperText-root": {
                color: "red",
                marginLeft: "2px",
            },
        },
    },
    "& .MuiDialogActions-root": {
        "& button": {
            minWidth: "80px !important",
        }
    }
}))

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditMemberModal = ({
    selectedMember,
    handleEditClose,
    openEdit,
    teamMemberToEdit,
}) => {
    const [loading, setLoading] = useState(false);
    const { user } = useAuthContext()
    const { enqueueSnackbar } = useSnackbar();

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            position: "",
            sinceDate: "",
            teamManager: false,
        },
    })

    const onClose = () => {
        handleEditClose()
        reset({ position: "", sinceDate: "" , teamManager:false})
    }

    const onSubmit = async (data) => {
        setLoading(true)
        try {
            const memberRef = doc(db, 'users', user.uid, "team", selectedMember)
            await updateDoc(memberRef, data);
            enqueueSnackbar("Team Member Edited!", { variant: "success" })
            setLoading(false)
            handleEditClose()
        } catch (error) {
            setLoading(false)
            enqueueSnackbar(error.message, { variant: "error" })
        }
    }
   
    useEffect(() => {
        if (selectedMember && teamMemberToEdit) {
            reset({
                position: teamMemberToEdit.position || "",
                sinceDate: teamMemberToEdit.sinceDate || "",
                teamManager: teamMemberToEdit.teamManager || false,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMember, teamMemberToEdit])

    return (
        <MainContainer>
            <StyledDialog
                open={openEdit}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{
                    "& .MuiDialog-paper": {
                        minWidth: { xs: "340px", sm: "440px", md: "540px" },
                        borderRadius: "2px",
                        p: 1,
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title"
                    sx={{
                        px: { xs: 2 }
                    }}
                >
                    Edit Team Member
                </DialogTitle>
                <DialogContent sx={{
                    overflow: "hidden",
                    px: { xs: 2 }
                }}>
                    <Grid container spacing={{ xs: 0, sm: 2 }}>
                        <Grid item xs={12} sm={7.5}>
                            <FormControl fullWidth>
                                <FormLabel sx={{ pt: 2, pb: 1 }}>Position</FormLabel>
                                <TextField
                                    variant="outlined"
                                    placeholder="Position"
                                    sx={{ borderRadius: 0, bgcolor: "#FFFFFF" }}
                                    fullWidth
                                    error={!!errors.position}
                                    {...register("position", { required: true })}
                                />
                                <FormHelperText>
                                    {errors.position && "This is required"}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4.5}>
                            <FormControl fullWidth>
                                <FormLabel sx={{ pt: 2, pb: 1 }}>Member Since</FormLabel>
                                <TextField
                                    variant="outlined"
                                    placeholder="Member Since"
                                    sx={{ borderRadius: 0, bgcolor: "#FFFFFF" }}
                                    fullWidth
                                    type="date"
                                    error={!!errors.sinceDate}
                                    {...register("sinceDate", { required: true })}
                                />
                                <FormHelperText>
                                    {errors.sinceDate && "This is required"}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{justifyContent:'space-between'}}>
                    <FormControl>
                        <Controller
                            name="teamManager"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel label="Team Manager" control={
                                    <Checkbox {...field}
                                    checked={field.value}
                                    />
                                }/>
                            )}
                        />
                    </FormControl>
                    <Stack direction="row" spacing={2}>
                        <Button variant='outlined' onClick={onClose}>Close</Button>
                        <Button
                            variant='contained'
                            disabled={loading}
                            onClick={handleSubmit(onSubmit)}
                            startIcon={loading && (
                                <CircularProgress
                                    style={{ width: "20px", height: "20px", color: "#fff" }}
                                />
                            )}
                        >
                            Save
                        </Button>
                    </Stack>
                </DialogActions>
            </StyledDialog>
        </MainContainer >
    )
}

export default EditMemberModal