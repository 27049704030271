import { FormControl, FormHelperText, FormLabel, TextField, useMediaQuery } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'


const DynamicTextField = (props) => {
    const { name, placeholder, label, clearErrors, control, defaultValue, ...rest } = props

    const isSm = useMediaQuery('(max-width:700px)');

    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error } }) => {
                return (
                    <FormControl fullWidth error={!!error}>
                        <FormLabel sx={{ pb: 1, }}>{label}</FormLabel>
                        <TextField
                            {...field}
                            variant="outlined"
                            placeholder={placeholder || label}
                            fullWidth
                            onChange={e => {
                                field.onChange(e);
                                clearErrors(field.name)
                            }}
                            size="small"
                            {...rest}
                        />
                        {error && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                )
            }}
            control={control}
            defaultValue=""
        />
    )
}

export default DynamicTextField