import { Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Affiliatedashboard from "./Affiliatedashboard";

function Affiliate() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    // <Box id="affiliate-dashboard-page">
    <Container sx={{ display: "flex", flexDirection: "Column" }}>
      {/* <Box sx={{ width: "100%", padding: "10px" }}>
        <Typography variant="h1" sx={{ textAlign: "center", color: "black" }}>
          Affiliate
        </Typography>
        <Typography variant="body1" sx={{ textAlign: "center" }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum
          repellendus minus obcaecati at saepe sunt, suscipit consectetur id
          officiis ipsam corporis eligendi doloremque? Alias enim saepe quo,
          facilis provident quos similique accusantium ullam tempora maxime cum
          non reprehenderit, sunt quaerat voluptatibus id eligendi dolorem
          minus.
        </Typography>
      </Box> */}
      <Affiliatedashboard />
    </Container>
    // </Box>
  );
}

export default Affiliate;
